import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TipoOperacaoEnum } from 'src/app/enum/tipoOperacao';
import { TelaEnum } from 'src/app/enum/tipoTela';
import { Filtro } from 'src/app/model/filtro.model';
import { ProdutoService } from '../../../service/produto.service';

@Component({
  selector: 'app-produto-list',
  templateUrl: './produto-list.component.html',
  styleUrls: ['./produto-list.component.css']
})
export class ProdutoListComponent implements OnInit {

  tela: TelaEnum = TelaEnum.produto;
  @Output() filtro: Filtro = new Filtro();
  produtos:any[] = [];
  showOverlay:boolean = false;
  count: number = 0;

  constructor(private produtoService: ProdutoService, private router: Router) { }

  async ngOnInit() {
    await this.start();
  }

  async start(){
    this.showOverlay = true;
    await this.construirFiltro();
    var produtos = await this.produtoService.listar(this.filtro);
    this.produtos = await this.montarAgrupamentoDosCards(produtos.value);
    this.showOverlay = false;
  }

  async montarAgrupamentoDosCards(produtos){
    let grupos = [];
    if(produtos != undefined){
      if(produtos.length){
        if(produtos.length <= this.filtro.quantidadePorPargina){
          grupos.push(produtos);
        }
        else{
          for(let i = 1; i <= produtos.length; i++){
            if(i%this.filtro.quantidadePorPargina == 0){
              grupos.push(produtos.slice(i-this.filtro.quantidadePorPargina, i));
              if(produtos.length - i <= this.filtro.quantidadePorPargina){
                grupos.push(produtos.slice(i, produtos.length));
                break;
              }
            }
          }
        }
      }
    }
    return grupos;
  }

  async construirFiltro(){
    await this.produtoService.count(this.filtro).then( x =>{
      let count = x.value[0].count;
      
      this.filtro.paginas = count <= this.filtro.quantidadePorPargina ? 1 : Math.ceil(count / this.filtro.quantidadePorPargina) ;
      this.filtro.totalDeRegistros =  count;
      this.count = count;
    });
  }

  async adicionarCards(filtro){
    this.showOverlay = true;
    let produtos = await this.produtoService.listar(filtro);    
    let grupos = await this.montarAgrupamentoDosCards(produtos.value);
    this.produtos.push(grupos[0]);
    this.showOverlay = false;
  }

  async pesquisar(filtro:Filtro = null){
    this.showOverlay = true;
    await this.produtoService.count(filtro).then(x =>{
      let count = x.value[0].count;
      filtro.paginas = count <= this.filtro.quantidadePorPargina ? 1 : Math.ceil(count / this.filtro.quantidadePorPargina) ;
      filtro.totalDeRegistros =  count;
      filtro.paginas = 1;
      filtro.pagina = 1;
      this.count = count;
    });

    await this.produtoService.listar(filtro).then(async x => this.produtos = await this.montarAgrupamentoDosCards(x.value));
    this.filtro = filtro;
    this.showOverlay = false;
  }

  async deletar(id){
    this.showOverlay = true;
    await this.produtoService.deletar(id).then(params=>{
      this.router.navigate(['/produto'],  { queryParams: { message: TipoOperacaoEnum.deletar.valueOf() }} );
      this.filtro.pagina = 1;
      this.start();
    });
    this.showOverlay = false;
  }
}
