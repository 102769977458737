<div class="container m-auto pt-3 pb-5">
    <div class="my-overlay" *ngIf="showOverlay">
      <div class="spinner-border m-5" role="status" style="color: white;" *ngIf="showOverlay">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <form [formGroup]="formulario" (ngSubmit)="onSubmit()">
      <div class="mb-3">
        <label for="NomeCliente" class="form-label">Nome completo</label>
        <input type="text" autocomplete="off" maxlength="150" class="form-control" formControlName="nome" id="NomeCliente" aria-describedby="NomeClienteHelp">
        <div *ngIf="formulario.get('nome').errors?.required && validations" class="alert alert-danger mt-2">Nome obrigatório</div>
      </div>
      <div class="mb-3">
        <label for="cpfCliente" class="form-label">CPF</label>
        <input type="text" autocomplete="off" maxlength="15" class="form-control" formControlName="cpf" id="cpfCliente" aria-describedby="cpfClienteHelp">
        <div *ngIf="formulario.get('cpf').errors?.required && validations" class="alert alert-danger mt-2">CPF obrigatório</div>
      </div>
      <div class="mb-3">
        <label for="telefoneCliente" class="form-label">Telefone</label>
        <input type="text" autocomplete="off" maxlength="20" class="form-control" formControlName="telefone" id="telefoneCliente" aria-describedby="telefoneClienteHelp">
        <div *ngIf="formulario.get('telefone').errors?.required && validations" class="alert alert-danger mt-2">Telefone obrigatório</div>
      </div>
      <div class="mb-3">
        <label for="whatsappCliente" class="form-label">Whatsapp</label>
        <input type="text" autocomplete="off" maxlength="20" class="form-control" formControlName="whatsapp" id="whatsappCliente" aria-describedby="whatsappClienteHelp">
        <div *ngIf="formulario.get('whatsapp').errors?.required && validations" class="alert alert-danger mt-2">Whatsapp obrigatório</div>
      </div>
      <div class="mb-3">
        <label for="ruaCliente" class="form-label">Rua</label>
        <input type="text" autocomplete="off" maxlength="150" class="form-control" formControlName="rua" id="ruaCliente" aria-describedby="ruaClienteHelp">
        <div *ngIf="formulario.get('rua').errors?.required && validations" class="alert alert-danger mt-2">Rua obrigatória</div>
      </div>
      <div class="mb-3">
        <label for="numeroCliente" class="form-label">Numero</label>
        <input type="text" autocomplete="off" maxlength="10" class="form-control" formControlName="numero" id="numeroCliente" aria-describedby="numeroClienteHelp">
        <div *ngIf="formulario.get('numero').errors?.required && validations" class="alert alert-danger mt-2">Valor obrigatório</div>
      </div>
      <div class="mb-3">
        <label for="cepCliente" class="form-label">CEP</label>
        <input type="text" autocomplete="off" maxlength="10" class="form-control" formControlName="cep" id="cepCliente" aria-describedby="cepClienteHelp">
        <div *ngIf="formulario.get('cep').errors?.required && validations" class="alert alert-danger mt-2">CEP obrigatório</div>
      </div>
      <div class="mb-3">
        <label for="complementoCliente" class="form-label">Complemento</label>
        <input type="text" autocomplete="off" maxlength="150" class="form-control" formControlName="complemento" id="complementoCliente" aria-describedby="complementoClienteHelp">
        <div *ngIf="formulario.get('complemento').errors?.required && validations" class="alert alert-danger mt-2">Complemento obrigatório</div>
      </div>
      <div class="mb-3">
        <label for="subBairroCliente" class="form-label">Sub bairro</label>
        <input type="text" autocomplete="off" maxlength="25" class="form-control" formControlName="sub_bairro" id="subBairroCliente" aria-describedby="sub_bairroClienteHelp">
        <div *ngIf="formulario.get('sub_bairro').errors?.required && validations" class="alert alert-danger mt-2">Sub bairro obrigatório</div>
      </div>
      <div class="mb-3">
        <label for="cidadeCliente" class="form-label">Selecione a Cidade</label>
        <select class="browser-default custom-select" (change)="setCidade($event);">
          <option value="0">Selecione</option>
          <option value="{{cidade.id}}" selected="{{id_cidade == cidade.id ? true : null}}"  *ngFor="let cidade of cidades">{{cidade.nome}}</option>
        </select>
        <div *ngIf="id_cidade == 0 && validations" class="alert alert-danger mt-2">Cidade obrigatório</div>
      </div>
      <div class="mb-3">
        <label for="bairroCliente" class="form-label">Bairro</label>
        <select class="browser-default custom-select" (change)="setBairro($event);">
          <option value="0">Selecione</option>
          <option value="{{bairro.id}}" selected="{{id_bairro == bairro.id ? true : null}}"  *ngFor="let bairro of bairros">{{bairro.nome}}</option>
        </select>
        <div *ngIf="id_bairro == 0 && validations" class="alert alert-danger mt-2">Bairro obrigatório</div>
      </div>
      <div class="d-flex mt-3 justify-content-center">
        <a routerLink="/cliente" class="btn btn-warning">Cancelar</a>
        <button type="submit" class="btn btn-danger ml-2">Salvar</button>
      </div>
    </form>
  </div>

  <app-modal #modal *ngIf="modal" [painel]="painel" [cliente]="cliente" [mensagem]="mensagem" [exibir]="modal" (saida)="fecharModal($event);"></app-modal>