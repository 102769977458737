import { Component, EventEmitter, Input, OnInit, OnChanges, Output, AfterViewInit, SimpleChanges, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TipoOperacaoEnum } from 'src/app/enum/tipoOperacao';
import { FiltroData } from 'src/app/model/filtro-data.model';
import { Filtro } from 'src/app/model/filtro.model';
import { AuthService } from 'src/app/service/auth.service';
import { TelaEnum } from '../../../enum/tipoTela';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit{

  @Input() data = [];
  @Input() tipoEntradaDeDado:TelaEnum;
  @Input() filtro: Filtro = new Filtro();
  @Input() motoristas;
  @Input() cidades;
  @Output() pesquisar: EventEmitter<any> = new EventEmitter();
  @Output() deletar: EventEmitter<any> = new EventEmitter();
  @Output() baixarRota: EventEmitter<any> = new EventEmitter();
  @Output() adicionarCards: EventEmitter<any> = new EventEmitter();
  @Output() adicionarAgendamento: EventEmitter<any> = new EventEmitter();
  @Output() agendarRemoverTodos: EventEmitter<any> = new EventEmitter();
  @Output() agendarCupons: EventEmitter<any> = new EventEmitter();
  

  titulo:string = '';
  urlEdit = '';
  urlRedirecionamento: string = '';
  mensagem:string = '';
  tipoMensagem:number = null;
  paginas = [];
  modal: boolean = false;
  mensagemModal:string = 'Deseja deletar esse registro?';
  idDelete: number = undefined;
  currentUser: any;
    
  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {
    this.authService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(): void {
    this.preRenderizacao();
    this.exibirMenssagem();
  }

  private esconderNotificacao(){
    setTimeout(function notificao() {
      let e = document.getElementById('notificacao');
      if(e != undefined){
        e.style.display = `none`;
      }
    }, 3000)
  }

  private preRenderizacao(){
    switch(parseInt(this.tipoEntradaDeDado.valueOf().toString())) {
      case TelaEnum.usuario: this.renderizarUsuario(); break;
      case TelaEnum.produto: this.renderizarProduto(); break;
      case TelaEnum.cliente: this.renderizarCliente(); break;
      case TelaEnum.cupom: this.renderizarCupom(); break;
      case TelaEnum.rota: this.renderizarRota(); break;
      case TelaEnum.intinerario: this.renderizarIntinerario(); break;
    }
  }
  
  private exibirMenssagem(){
    this.route.queryParams.subscribe(params =>{
      const message = parseInt(params['message']);
      if(!isNaN(message)){
        switch(message){
          case TipoOperacaoEnum.error: this.mensagem = 'Houve um erro ao manipular o registro.'; break;
          case TipoOperacaoEnum.criar: this.mensagem = 'Registro salvo com sucesso.'; break;
          case TipoOperacaoEnum.editar: this.mensagem = 'Registro alterado com sucesso.'; break;
          case TipoOperacaoEnum.deletar: this.mensagem = 'Registro deletado com sucesso.'; break;
          case TipoOperacaoEnum.baixaCupom: this.mensagem = 'Baixa no cupom com sucesso.'; break;
        }
        this.tipoMensagem = message;
        this.esconderNotificacao();
      }
    });
  }
  
  editarRota(id, data_rota, id_usuario){
    this.router.navigate([`rota/form/${id}/${data_rota}/${id_usuario}`])
  }

  baixa(id){
    this.router.navigate([`intinerario/baixa/${id}`])
  }

  private renderizarUsuario(){
    this.titulo = 'Usuarios';
    this.urlRedirecionamento = 'usuario';
    this.urlEdit = 'usuario/form/'
  }

  private renderizarIntinerario(){
    this.titulo = 'Intinerario';
    this.urlRedirecionamento = 'intinerario';
  }

  private renderizarProduto(){
    this.titulo = 'Produtos';
    this.urlRedirecionamento = 'produto';
  }
  
  private renderizarCliente(){
    this.titulo = 'Clientes';
    this.urlRedirecionamento = 'cliente';
  }
  
  private renderizarCupom(){
    this.titulo = 'Cupom';
    this.urlRedirecionamento = 'cupom';
  }

  private renderizarRota(){
    this.titulo = 'Rotas';
    this.urlRedirecionamento = 'rota';
  }

  exibirModal(id){
    this.modal = true;
    this.idDelete = id;
  }

  fecharModal(validar){
    if(validar) this.deletar.emit(this.idDelete);
    
    this.modal = false;
    this.idDelete = undefined;
  }

  adicionarCardsAoGrid(){
    if(this.currentUser.role == 2){
      this.filtro.dados.role = this.currentUser.role;
      this.filtro.dados.id_motorista = this.currentUser.id;
    }
    this.filtro.pagina += 1;
    this.adicionarCards.emit(this.filtro);
  }
  
  adicionarNaAgenda(event, id){
    this.adicionarAgendamento.emit({ id: id , agendar: event.target.checked});
  }

  agendarRemoverTodosCupons(event){
    let checkBoxs = document.getElementsByClassName('form-check-input');
    let listaId = [];
    if(checkBoxs.length){
      for (var i = 0; i < checkBoxs.length; i++) {
        checkBoxs[i]['checked'] = event;
        listaId.push(checkBoxs[i].id)
      }
    }
    this.agendarRemoverTodos.emit({'status': event, 'id': listaId});
  }

  agendarCuponsSelecionados(event){
    this.agendarCupons.emit(event);
  }

  buscarPesquisa(dados: FiltroData){
    if(this.currentUser.role == 2){
      this.filtro.dados.role = this.currentUser.role;
      this.filtro.dados.id_motorista = this.currentUser.id;
    }
    this.filtro.dados = dados;
    this.pesquisar.emit(this.filtro);
  }

  baixarRotaPeloId(id, nomeMotorista, data){
    let pacote = {
      id: id,
      nome: nomeMotorista,
      data: data
    };
    this.baixarRota.emit(pacote);
  }
}
