import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TelaEnum } from 'src/app/enum/tipoTela';
import { FiltroData } from 'src/app/model/filtro-data.model';
import { Filtro } from 'src/app/model/filtro.model';
import { AuthService } from 'src/app/service/auth.service';
import { EnderecoService } from 'src/app/service/endereco.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit, OnChanges {

  @Input() tipoEntradaDeDado:TelaEnum;
  @Input() titulo:string = '';
  @Input() urlRedirecionamento:string;
  @Input() count: number= 0;
  @Input() cidades;
  @Input() motoristas;
  @Input() filtro: Filtro;
  @Input() data = [];
  @Output() pesquisar: EventEmitter<any> = new EventEmitter();
  @Output() agendarRemoverTodos: EventEmitter<boolean> = new EventEmitter();
  @Output() agendarCupons: EventEmitter<any> = new EventEmitter();
  bairros = [];
  isCollapsed = false;
  agendar = false;
  url= '';
  cupomAgendamento: boolean = false;
  currentUser: any;

  constructor(private authService: AuthService, private enderecoService:EnderecoService) {
    this.authService.currentUser.subscribe(x => this.currentUser = x);
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(this.tipoEntradaDeDado == TelaEnum.cupom || this.tipoEntradaDeDado == TelaEnum.intinerario){
      if(changes.data != undefined){
        if(changes.data.currentValue.length){
          this.cupomAgendamento = changes.data.currentValue[0].some(x=> x.flag_pago == 0);
        }
      }
    }
  }

  ngOnInit(): void {
    this.filtro.dados = new FiltroData();
    this.url = `/${this.urlRedirecionamento}/form`;
  }

  collapse = (e)=> this.isCollapsed = e;

  async carregarBairro(data){
    let id = data.target.value;
    if(id != '0' && id != 0 && id != undefined){
      let bairros = await this.enderecoService.listarBairroPorCidade(id);
      this.bairros = bairros.value;
    }
  }
 
  agendarRemoverTodosOsCuponsDaTela(){
    this.agendar = !this.agendar;
    this.agendarRemoverTodos.emit(this.agendar);
  }
  
  agendarCuponsSelecionados(){
    this.agendarCupons.emit(true);
  }

  enviarPesquisa(){
    if(this.tipoEntradaDeDado == 5 || this.tipoEntradaDeDado == 6)
      this.filtro.dados.id_motorista = ((document.getElementById("motorista") as HTMLInputElement).value);
    
    if(this.tipoEntradaDeDado != 5 && this.tipoEntradaDeDado != 6)
      this.filtro.dados.palavraChave = ((document.getElementById("palavraChave") as HTMLInputElement).value);
    
    if(this.tipoEntradaDeDado == 3){
      this.filtro.dados.cpf = ((document.getElementById("CPF") as HTMLInputElement).value);
      let valor = (document.getElementById("situacao") as HTMLInputElement);
      this.filtro.dados.situacao = (valor.value);
    }

    if(this.tipoEntradaDeDado == 4 || this.tipoEntradaDeDado == 5 || this.tipoEntradaDeDado == 6){
      this.filtro.dados.dataDe = ((document.getElementById("dataDe") as HTMLInputElement).value);
      this.filtro.dados.dataAte = ((document.getElementById("dataAte") as HTMLInputElement).value);
    }

    if(this.tipoEntradaDeDado == 4)    
      this.filtro.dados.status = ((document.getElementById("status") as HTMLInputElement).value);
      
      
    if(this.tipoEntradaDeDado  == 3 || this.tipoEntradaDeDado == 4 || this.tipoEntradaDeDado == 6){
      this.filtro.dados.id_cidade = ((document.getElementById("cidade") as HTMLInputElement).value);
      this.filtro.dados.id_bairro = ((document.getElementById("bairro") as HTMLInputElement).value);
    }

    this.pesquisar.emit(this.filtro.dados);
  }
}
