import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Incorporacao } from 'src/app/model/incorporacao.model';

@Component({
  selector: 'app-cupom-valor-residual',
  templateUrl: './cupom-valor-residual.component.html',
  styleUrls: ['./cupom-valor-residual.component.css']
})
export class CupomValorResidualComponent {

  @Input() exibir:boolean = false;
  @Input() dadosIncorporacao: Incorporacao = new Incorporacao();
  @Output() saida: EventEmitter<boolean> = new EventEmitter();

  retorno = (event) => this.saida.emit(event);
  
}
