<div class="modal d-block mt-5" tabindex="-1" >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Cliente com saldo residual na ultima compra</span>
        </div>
        <div class="modal-body" *ngIf="dadosIncorporacao.valor > 0">
          <div class="mb-3 mt-3">
            <h4 for="" class="form-h2">Este cliente possui R$ {{ dadosIncorporacao.valor | number:'2.2-2'   }} de credito em sua ultima compra, deseja adicionar o valor a esse cupom? </h4>
          </div>
        </div>
        <div class="modal-body" *ngIf="!(dadosIncorporacao.valor > 0)">
          <div class="mb-3 mt-3">
            <h4 for="" class="form-h2">Este cliente possui R$ {{ dadosIncorporacao.valor *(-1.0) | number:'2.2-2'   }} de debito em sua ultima compra, deseja adicionar o valor a esse cupom? </h4>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="retorno(false);">Cancelar</button>
          <button type="button" class="btn btn-danger" (click)="retorno(true);">Adicionar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="my-overlay" *ngIf="exibir" style="z-index: 99 !important;"></div>