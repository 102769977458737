import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TipoOperacaoEnum } from 'src/app/enum/tipoOperacao';
import { TelaEnum } from 'src/app/enum/tipoTela';
import { Filtro } from 'src/app/model/filtro.model';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from '../../../service/usuario.service';

@Component({
  selector: 'app-usuario-list',
  templateUrl: './usuario-list.component.html',
  styleUrls: ['./usuario-list.component.css']
})
export class UsuarioListComponent implements OnInit {

  tela: TelaEnum = TelaEnum.usuario;
  @Output() filtro:Filtro = new Filtro();
  usuarios:Usuario[] = [];
  showOverlay:boolean = false;
  palavraChave = '';
  esconderBotaoPaginacao = true;
  count: number = 0;

  constructor(private usuarioService: UsuarioService, private router: Router) { }

  async ngOnInit() {
    this.start();
  }

  async montarAgrupamentoDosCards(usuarios){
    let grupos = [];
    if(usuarios != undefined){
      if(usuarios.length){
        if(usuarios.length <= this.filtro.quantidadePorPargina){
          grupos.push(usuarios);
        }
        else{
          for(let i = 1; i <= usuarios.length; i++){
            if(i%this.filtro.quantidadePorPargina == 0){
              grupos.push(usuarios.slice(i-this.filtro.quantidadePorPargina, i));
              if(usuarios.length - i <= this.filtro.quantidadePorPargina){
                grupos.push(usuarios.slice(i, usuarios.length));
                break;
              }
            }
          }
        }
      }
    }
    return grupos;
  }

  async start(){
    this.showOverlay = true;
    await this.construirFiltro();
    var usuarios = await this.usuarioService.listar(this.filtro);
    this.usuarios = await this.montarAgrupamentoDosCards(usuarios.value);
    this.showOverlay = false;
  }

  async construirFiltro(){
    await this.usuarioService.count(this.filtro).then( x =>{
      let count = x.value[0].count;
      
      this.filtro.paginas = count <= this.filtro.quantidadePorPargina ? 1 : Math.ceil(count / this.filtro.quantidadePorPargina) ;
      this.filtro.totalDeRegistros =  count;
    });
  }

  async adicionarCards(filtro){
    this.showOverlay = true;
    let usuarios = await this.usuarioService.listar(filtro);
    let grupos = await this.montarAgrupamentoDosCards(usuarios.value);
    this.usuarios.push(grupos[0]);
    this.showOverlay = false;
  }

  async pesquisar(filtro:Filtro = null){
    this.showOverlay = true;
    await this.usuarioService.count(filtro).then( x =>{
      let count = x.value[0].count;
      filtro.paginas = count <= this.filtro.quantidadePorPargina ? 1 : Math.ceil(count / this.filtro.quantidadePorPargina) ;
      filtro.totalDeRegistros =  count;
      filtro.paginas = 1;
      filtro.pagina = 1;
      this.count = count;
    });

    await this.usuarioService.listar(filtro).then(async x => this.usuarios = await this.montarAgrupamentoDosCards(x.value));
    this.filtro = filtro;
    this.showOverlay = false;
  }

  async deletar(id){
    this.showOverlay = true;
    await this.usuarioService.deletar(id).then(async params=>{
      this.router.navigate(['/usuario'],  { queryParams: { message: TipoOperacaoEnum.deletar.valueOf() }} );
      this.start();
    });
    this.showOverlay = false;
  }
}
