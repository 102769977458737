<div class="container m-auto pt-3 pb-5">
  <div class="my-overlay" *ngIf="showOverlay">
    <div class="spinner-border m-5" role="status" style="color: white;" *ngIf="showOverlay">
      <span class="visually-hidden"></span>
    </div>
  </div>
  <form [formGroup]="formulario" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="mb-3">
      <label for="NomeUsuario" class="form-label">Nome completo</label>
      <input type="text" maxlength="150" class="form-control" formControlName="nome" id="NomeUsuario" aria-describedby="NomeUsuarioHelp">
      <div *ngIf="formulario.get('nome').errors?.required && validations" class="alert alert-danger mt-2">Nome obrigatório</div>
    </div>
    <div class="mb-3">
      <label for="emailUsuario" class="form-label">Email</label>
      <input type="text" maxlength="150" class="form-control" formControlName="email" id="emailUsuario" aria-describedby="emailUsuarioHelp">
      <div *ngIf="formulario.get('email').errors?.required && validations" class="alert alert-danger mt-2">Email obrigatório</div>
    </div>
    <div class="mb-3">
      <label for="loginUsuario" class="form-label">Login</label>
      <input type="text" maxlength="20" class="form-control" formControlName="login" id="loginUsuario" aria-describedby="loginUsuarioHelp">
      <div *ngIf="formulario.get('login').errors?.required && validations" class="alert alert-danger mt-2">Login obrigatório</div>
    </div>
    <div class="mb-3">
      <label for="senhaUsuario" class="form-label">{{ (id != undefined ? "Insira uma nova senha" : "Senha")}}</label>
      <input type="password" maxlength="50" class="form-control" formControlName="senha" id="senhaUsuario" aria-describedby="senhaUsuarioHelp">
      <div *ngIf="formulario.get('senha').errors?.required && validations" class="alert alert-danger mt-2">Senha obrigatório</div>
    </div>
    <div class="mb-3">
      <div class="form-check">
        <input class="form-check-input" type="radio" formControlName="role" value="1" required name="role" id="adminUsuario">
        <label class="form-check-label" for="adminUsuario">
          Administrador
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" formControlName="role" value="2" required name="role" id="motoristaUsuario" >
        <label class="form-check-label" for="motoristaUsuario">
          Motorista
        </label>
      </div>
      <div *ngIf="formulario.get('role').errors?.required && validations" class="alert alert-danger mt-2">Permissao obrigatória</div>
    </div>

    <div class="d-flex mt-3 justify-content-center">
      <a routerLink="/usuario" class="btn btn-warning">Cancelar</a>
      <button type="submit" class="btn btn-danger ml-2">Salvar</button>
    </div>
  </form>
</div>