<div class="modal d-block mt-5" tabindex="-1" >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Confirme os dados para finalizar</span>
        </div>
        <div class="modal-body mb-3">

            <div class="mb-3 mt-3" *ngIf="valor < 0">
                <label class="form-label">Confirme o valor de crédito de <strong>R$ {{ valor * (-1) | number:'2.1-2' }}</strong> para finalizar o serviço.</label>
            </div>
        
            <div class="mb-3 mt-3" *ngIf="valor > 0">
                <label class="form-label">Confirme o valor de repasse de <strong>R$ {{ valor | number:'2.1-2' }}</strong> e selecione uma data para agendamento da cobrança.</label>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 p-0 mt-2 pl-1 pr-1" *ngIf="valor > 0">
              <label for="dataCobranca" class="form-label">Data de Cobrança</label>
              <input type="date" class="form-control" aria-describedby="dataCobranca" (change)="setDataAgendamento($event);" value="" />
            </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="retorno(false);">Cancelar</button>
          <button type="button" class="btn btn-danger" [disabled]="(valor > 0 && dataAgendamento == '')" (click)="retorno(true);">Finalizar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="my-overlay" *ngIf="exibir" style="z-index: 99 !important;" ></div>