import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/model/usuario.model';
import { AuthService } from 'src/app/service/auth.service';
import { UsuarioService } from 'src/app/service/usuario.service';

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.css']
})
export class UsuarioFormComponent implements OnInit {
  
  formulario:FormGroup;
  validations:boolean = false;
  usuario: Usuario;
  id:number;
  showOverlay:boolean = false;
  currentUser;

  constructor(private router: Router, private route: ActivatedRoute, private usuarioService: UsuarioService, private formBuilder: FormBuilder, private authService: AuthService) { 
    this.authService.currentUser.subscribe(x => this.currentUser = x); 
  }

  ngOnInit(): void {
    this.construirFormulario();
    this.identificarOperacao();
    this.usuario.created_by = this.currentUser.id;
  }

  construirFormulario(){
    this.formulario = this.formBuilder.group({
      id:0,
      nome: ['', Validators.required],
      email:['', Validators.required],
      login: ['', Validators.required],
      senha: ['', Validators.required], 
      role: [2, Validators.required]
    });
  }

  alimentarFormulario(){
    this.formulario.patchValue({
      id: this.usuario.id,
      nome: this.usuario.nome,
      email: this.usuario.email,
      login: this.usuario.login,
      role: this.usuario.role.toString()
    });
  }

  validarFormulario(){
    if(this.formulario.valid){
      this.validations = false;
    }else{
      this.validations = true;
    }
  }

  async identificarOperacao(){
    this.id = this.route.snapshot.params['id'];
    if(this.id != undefined){
      var usuario = await this.usuarioService.encontrarPeloId(this.id);
      this.usuario = usuario.value[0];
      this.alimentarFormulario();
    }
  }

  async onSubmit(){
    this.validarFormulario();
    if(!this.validations){
      this.usuario = this.formulario.value;
      this.showOverlay = true;
      if(this.id != undefined) 
        await this.usuarioService.editar(this.usuario)
          .then(x=>{
            this.showOverlay = false;
            this.router.navigate(['/usuario'],  { queryParams: { message: 2 }} );
          })
          .catch(x=>{
            this.showOverlay = false;
            this.router.navigate(['/usuario'],  { queryParams: { message: 1 }} );
          });
      else 
        await this.usuarioService.criar(this.usuario)
        .then(x=>{
          this.showOverlay = false;
          this.router.navigate(['/usuario'],  { queryParams: { message: 1 }} );
        })
        .catch(x=>{
          this.showOverlay = false;
          this.router.navigate(['/usuario'],  { queryParams: { message: 0 }} );
        });
    }
  }
}
