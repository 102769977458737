import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Produto } from 'src/app/model/produto.model';
import { AuthService } from 'src/app/service/auth.service';
import { ProdutoService } from 'src/app/service/produto.service';

@Component({
  selector: 'app-produto-form',
  templateUrl: './produto-form.component.html',
  styleUrls: ['./produto-form.component.css']
})
export class ProdutoFormComponent implements OnInit {
  
  formulario:FormGroup;
  validations:boolean = true;
  produto: Produto;
  id:number = 0;
  showOverlay:boolean = false;
  showPackForm: boolean = false;
  currentUser;

  constructor(private router: Router, private route: ActivatedRoute, private produtoService: ProdutoService, private formBuilder: FormBuilder, private authService: AuthService) { 
    this.authService.currentUser.subscribe(x => this.currentUser = x);  
  }

  ngOnInit(): void {
    this.construirFormulario();
    this.identificarOperacao();
  }

  construirFormulario(){
    this.formulario = this.formBuilder.group({
      id:0,
      id_produto: 0,
      nome_pai: ['', Validators.required],
      preco:['', Validators.required],
      lote: ['1'],
      nome_filho: [''],
      flag_devolucao: [false]
    });
  }

  alimentarFormulario(){
    this.showPackForm = !(this.produto.nome_filho == null)
    this.formulario.patchValue({
      id: this.produto.id,
      nome_pai: this.produto.nome_pai,
      preco: this.produto.preco,
      lote: this.produto.lote,
      flag_devolucao: this.produto.dev_pai,
      nome_filho: this.produto.nome_filho
    });
  }

  validarFormulario() {
    let isValid = (this.formulario.valid ? true: false);
    if(isValid){
      if(this.showPackForm){
        if(this.formulario.controls['nome_filho'].value != '' && this.formulario.controls['nome_filho'].value != undefined){
          return true
        }
        return false;
      }else{
        return true;
      }
    }
  }
  
  construirFormularioPacote(e){
    this.showPackForm = e.target.checked
  }
  
  async identificarOperacao(){
    let id = this.route.snapshot.params['id'];
    if(id != undefined){
      this.id = id;
      var produto = await this.produtoService.encontrarPeloId(this.id);
      this.produto = produto.value[0];
      this.alimentarFormulario();
    }
  }

  async onSubmit(){
    this.produto = this.formulario.value;
    this.produto.created_by = this.currentUser.id;
    this.produto.preco =  parseFloat(this.produto.preco.toString().replace(',', '.'));
    this.validarFormulario();

    if(this.validations){
      this.showOverlay = true;
      if(this.id != 0) 
        await this.produtoService.editar(this.produto)
          .then(x=>{
            this.showOverlay = false;
            this.router.navigate(['/produto'],  { queryParams: { message: 2 }} );
          })
          .catch(x=>{
            this.showOverlay = false;
            this.router.navigate(['/produto'],  { queryParams: { message: 1 }} );
          });
      else 
        await this.produtoService.criar(this.produto)
        .then(x=>{
          this.showOverlay = false;
          this.router.navigate(['/produto'],  { queryParams: { message: 1 }} );
        })
        .catch(x=>{
          this.showOverlay = false;
          this.router.navigate(['/produto'],  { queryParams: { message: 0 }} );
        });
    }
  }
}