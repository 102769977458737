import * as moment from 'moment';

export class FiltroData {
    palavraChave: string = '';
    situacao: string = '';
    cpf: string = '';
    status: string = ''; // se existe algum cupom aberto para o cliente
    dataDe:string = moment(new Date()).format("yyyy-MM-DD").toString();
    dataAte:string = moment(new Date()).format("yyyy-MM-DD").toString();
    id_motorista:string = '';
    id_cidade:string = '';
    id_bairro: string = '';
    role:boolean = false;
    created_by: number = 0;
}