import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';

import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';

import { ClienteListComponent } from './pages/cliente/cliente-list/cliente-list.component';
import { ClienteFormComponent } from './pages/cliente/cliente-form/cliente-form.component';

import { ProdutoListComponent } from './pages/produto/produto-list/produto-list.component';
import { ProdutoFormComponent } from './pages/produto/produto-form/produto-form.component';

import { UsuarioFormComponent } from './pages/usuario/usuario-form/usuario-form.component';
import { UsuarioListComponent } from './pages/usuario/usuario-list/usuario-list.component';

import { CupomListComponent } from './pages/cupom/cupom-list/cupom-list.component';
import { CupomFormComponent } from './pages/cupom/cupom-form/cupom-form.component';

import { RotaListComponent } from './pages/rota/rota-list/rota-list.component';
import { RotaFormComponent } from './pages/rota/rota-form/rota-form.component';

import { IntinerarioListComponent } from './pages/intinerario/intinerario-list/intinerario-list.component';
import { IntinerarioFormComponent } from './pages/intinerario/intinerario-form/intinerario-form.component';
import { IntinerarioAgendamentoComponent } from './pages/intinerario/intinerario-agendamento/intinerario-agendamento.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent},

  { path: 'usuario', component: UsuarioListComponent, canActivate: [AuthGuard] },
  { path: 'usuario/form', component: UsuarioFormComponent, canActivate: [AuthGuard] },
  { path: 'usuario/form/:id', component: UsuarioFormComponent, canActivate: [AuthGuard] },

  { path: 'produto', component: ProdutoListComponent, canActivate: [AuthGuard] },
  { path: 'produto/form', component: ProdutoFormComponent, canActivate: [AuthGuard] },
  { path: 'produto/form/:id', component: ProdutoFormComponent, canActivate: [AuthGuard] },
  
  { path: 'cliente', component: ClienteListComponent },
  { path: 'cliente/form', component: ClienteFormComponent, canActivate: [AuthGuard] },
  { path: 'cliente/form/:id', component: ClienteFormComponent, canActivate: [AuthGuard] },
  
  { path: 'cupom', component: CupomListComponent, canActivate: [AuthGuard] },
  { path: 'cupom/form', component: CupomFormComponent, canActivate: [AuthGuard] },
  { path: 'cupom/form/:id', component: CupomFormComponent, canActivate: [AuthGuard] },

  { path: 'rota', component: RotaListComponent, canActivate: [AuthGuard] },
  { path: 'rota/form/:id/:data/:motorista', component: RotaFormComponent, canActivate: [AuthGuard] },
  { path: 'rota/form', component: RotaFormComponent, canActivate: [AuthGuard] },
  
  { path: 'intinerario', component: IntinerarioListComponent, canActivate: [AuthGuard] },
  { path: 'intinerario/baixa/:id', component: IntinerarioFormComponent, canActivate: [AuthGuard] },

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
