<div *ngIf="intinerario != undefined" class="container m-auto pt-3 pb-5">
    <div class="my-overlay" *ngIf="showOverlay">
      <div class="spinner-border m-5" role="status" style="color: white;" *ngIf="showOverlay">
        <span class="visually-hidden"></span>
      </div>
    </div>

    <div class="card mt-5">
        <div class="card-header text-center h4">
            <b>Informações</b>
        </div>
        <div class="card-body">
            <div class="mb-4">
                <div class="card">
                    <ul class="list-group list-group-flush">
                    <li class="list-group-item"><b>Nome: </b> {{ intinerario.nome }}</li>
                    <li class="list-group-item"><b>Data de cobrança: </b> {{ intinerario.data_cobranca | date:'dd/MM/yyyy'  }} </li>
                    <li class="list-group-item"><b>Valor da cobrança: </b> R$ {{ intinerario.valor | number:'2.1-2' }}</li>
                    </ul>
                </div>    
            </div>    
            <div class="mb-3"> 
                <h3><b>Produtos comprados:</b></h3>
                
                <div class="card">
                    <ul class="list-group list-group-flush">
                    <li class="list-group-item" *ngFor="let item of produtosDeDevolucao"><b>{{ item.limiteDeDelucao }}x - </b> {{ item.nome_pai }}</li>
                    </ul>
                </div>    
            </div>    

        </div>
    </div>
    
    <div class="card mt-5">
      <div class="card-header text-center h4">
          <b>Acerto</b>
      </div>
      <div class="card-body">
        <div class="mb-3">        
            <div class="row">
              <div class="col-12 p-0 pl-1 pr-1">
                <h3>Devolução</h3>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12 col-md-6 col-lg-4 p-0 mt-2 pl-1 pr-1" *ngFor="let item of produtosDeDevolucao">
               
                <div class="card" *ngIf="item.flag_devolucao == 1">
                  <div class="card-body">
                    <input *ngIf="item.nome_filho == null" type="text" class="form-control mb-4" value="{{item.nome_pai}}" readonly/>
                    <select *ngIf="item.nome_filho != null" class="browser-default custom-select mb-4" (change)="lotearVenda(item.id, item.lote);" [disabled]="currentUser.role != 1">
                      <option value="1">{{item.nome_filho}}</option>
                      <option value="{{ item.lote }}">{{item.nome_pai}}</option>
                    </select>
                    <h4>Adicionados <strong>{{ item.quantidade }}</strong></h4>
                    <a class="card-link btn btn-danger" (click)="NaoResgatarProdutoDevolucao(item.id);">Remover</a>
                    <a class="card-link btn btn-danger" (click)="ResgatarProdutoDevolucao(item.id);">Adicionar</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
          
          <div class="mb-3">
            <label  class="form-label">Valor pago pelo cliente:</label>
            <input type="text" (change)="setValorPagoPelocliente($event);" class="form-control" aria-describedby="precoProdutoHelp" value="0">
          </div>
          
          <div class="mt-2">
            <strong>Valor atencipado</strong> R$ {{ (intinerario.valor_adiantado + intinerario.incorporacao * -(1.0)) | number:'2.1-2' }}
          </div>
          <div class="mt-2">
            <strong>Valor Restante</strong> R$ {{ (intinerario.valor - intinerario.valor_adiantado) + intinerario.incorporacao   | number:'2.1-2' }}
          </div>
          <div class="mt-2">
            <strong>Valor Repasse</strong> R$ {{ ((valorDaDevolucao * (-1)) - intinerario.valor_pago)  + intinerario.incorporacao | number:'2.1-2' }}
          </div>
      </div>
    </div>

    <div class="d-flex mt-5 justify-content-center">
      <a routerLink="/intinerario" class="btn btn-warning">Cancelar</a>
      <button type="submit" class="btn btn-danger ml-2" (click)="submit();">Salvar</button>
    </div>
  </div>
  <app-intinerario-repasse *ngIf="exibir" [valor]="((valorDaDevolucao * (-1)) - intinerario.valor_pago)  + intinerario.incorporacao" [exibir]="exibir" (saida)="fecharModal($event);"></app-intinerario-repasse>