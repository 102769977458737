<div class="my-overlay" *ngIf="showOverlay">
    <div class="spinner-border m-5" role="status" style="color: white;" *ngIf="showOverlay">
      <span class="visually-hidden"></span>
    </div>
</div>
<app-list 
  [data]="cupons" 
  [tipoEntradaDeDado]="tela"
  [cidades]="cidades" 
  [data]="cupons" 
  [filtro]="filtro"
  (adicionarCards)="adicionarCards($event)" 
  (pesquisar)="pesquisar($event);" 
  (adicionarAgendamento)="adicionarAgendamento($event);" 
  (agendarRemoverTodos)="agendarRemoverTodosOsCuponsDaTela($event);"
  (agendarCupons)="agendarCuponsSelecionados($event);"
  (deletar)="deletar($event);">
</app-list>
<app-cupom-agendamento *ngIf="agendar" [motoristas]="motoristas" [cupons]="selecaoAgenda" [exibir]="agendar" (saida)="fecharModal($event);"></app-cupom-agendamento>