<div class="container pt-3 pb-5">
  <div>
    <div>
      <app-filter 
        [tipoEntradaDeDado]="tipoEntradaDeDado" 
        [titulo]="titulo" 
        [urlRedirecionamento]="urlRedirecionamento" 
        [motoristas]="motoristas" 
        [cidades]="cidades" 
        [filtro]="filtro" 
        [data]="data"
        (pesquisar)="buscarPesquisa($event);" 
        (agendarRemoverTodos)="agendarRemoverTodosCupons($event);"
        (agendarCupons)="agendarCuponsSelecionados($event);">
      </app-filter>
      
        <div *ngIf="mensagem.length" id="notificacao" class="mt-3">
        <div *ngIf="tipoMensagem == 0" class="alert alert-danger" role="alert">
          {{ mensagem }}
        </div>
        <div *ngIf="tipoMensagem != 0" class="alert alert-success" role="alert">
          {{ mensagem }}
        </div>
      </div>

      <div class="container" *ngIf="data != undefined && data.length > 0 && data[0] != undefined; else empty" >
        <div class="row" *ngFor="let info of data">
          <div class="col-sm-6 col-md-6 col-lg-4 p-0 mt-2 pl-1 pr-1" *ngFor="let field of info">


            <div class="card h-100" *ngIf="(currentUser.role == 2 && tipoEntradaDeDado != 3) || (field.flag_pago != 1 && currentUser.role == 2 && tipoEntradaDeDado == 6) || currentUser.role == 1" >
              <div class="card-header" *ngIf="(tipoEntradaDeDado == 4 || tipoEntradaDeDado == 6) && currentUser.role != 2">
                <div class="form-check form-check-inline" *ngIf="!field.flag_pago && field.valor > 0;else naoAgendar">
                  <input class="form-check-input" type="checkbox" id="{{ field.id }}" (click)="adicionarNaAgenda($event, field.id);" value="option2">
                  <label class="form-check-label" for="{{ field.id }}">Clique aqui para  {{tipoEntradaDeDado == 4 ?  'agendar' : 'reagendar' }}</label>
                </div>
                <ng-template #naoAgendar>
                  <div class="form-check form-check-inline" >
                    <label class="form-check-label" for="{{ field.id }}">Cupom finalizado</label>
                  </div>
                </ng-template>
              </div>  
              <div class="card-body pb-0" [ngSwitch]="tipoEntradaDeDado">
                <div *ngSwitchCase="1"> <!-- USUARIO  -->
                  <h3 class="card-title"><strong>Nome:</strong> {{ field.nome }}</h3>
                  <h3 class="card-title"><strong>Login:</strong> {{ field.login }}</h3>
                  <h3 class="card-title"><strong>Tipo:</strong> {{ field.role == 1 ? "Administrador" : "Mostorista" }}</h3>
                </div>
      
                <div *ngSwitchCase="2"> <!-- PRODUTO -->
                  <h3 class="card-title"><strong>Nome:</strong> {{ field.nome_pai }}</h3>
                  <h3 class="card-title" *ngIf="field.lote > 1"><strong>Quantidade:</strong> {{ field.lote }}</h3> 
                  <h3 class="card-title" *ngIf="field.nome_filho != null"><strong>Nome unidade:</strong> {{ field.nome_filho }}</h3> 
                  <h3 class="card-title"><strong>Preço:</strong> R$ {{ field.preco | number:'2.2-2'  }}</h3>
                </div>
      
                <div *ngSwitchCase="3" > <!-- CLIENTE -->
                  <h3 class="card-title"><strong>Nome:</strong> {{ field.nome }}</h3>
                  <h3 class="card-title"><strong>Cidade:</strong> {{ field.nome_cidade }}</h3>
                  <h3 class="card-title"><strong>Bairro:</strong> {{ field.nome_bairro }}</h3>
                  <h3 class="card-title"><strong>CPF:</strong> {{ field.cpf }}</h3>
                  <h3 class="card-title"><strong>Residual:</strong> {{ field.valor * (-1.0) | number:'2.2-2'  }}</h3>

                </div>
      
                <div *ngSwitchCase="4"> <!-- CUPOM -->
                  <h3 class="card-title text-center" *ngIf="field.flag_repasse"><strong>CUPOM DE REPASSE</strong></h3>
                  <h3 class="card-title"><strong>Cliente:</strong> {{ field.nome_cliente }}</h3>
                  <h3 class="card-title"><strong>Data da cobranca:</strong> {{ field.data_cobranca |  date: 'dd/MM/yyyy' }}</h3>
                  <h3 class="card-title" *ngIf="!field.flag_pago"><strong>Bairro:</strong> {{ field.nome_bairro }}</h3>
                  <h3 class="card-title"><strong>Valor total:</strong> R$ {{ field.valor | number:'2.2-2'  }}</h3>
                  <h3 class="card-title"><strong>Valor adiantado:</strong> R$ {{ field.valor_adiantado | number:'2.2-2'  }}</h3>
                  <!-- <h3 class="card-title" *ngIf="field.valor_pago != 0"><strong>Valor repasse:</strong> R$ {{ field.valor_pago == 0 ? 0 :field.valor_pago - field.valor  | number:'2.2-2'  }}</h3> -->
                  <h3 class="card-title"><strong>Valor final:</strong> R$ {{ field.valor - field.valor_adiantado | number:'2.2-2'  }}</h3>
                  <h3 class="card-title" *ngIf="field.flag_pago"><strong>Valor pago:</strong> R$ {{ field.valor_pago | number:'2.2-2'  }}</h3>
                  <h3 class="card-title" *ngIf="field.motorista != null && currentUser.role != 2"><strong>Motorista:</strong> {{  field.motorista }}</h3>
                  <h3 class="card-title" *ngIf="field.motorista != null && currentUser.role != 2 && !field.flag_pago"><strong>Dia da cobranca:</strong> {{  field.data_rota | date:'dd/MM/yyyy'  }}</h3>
                </div>
                    
                <div *ngSwitchCase="5"> <!-- ROTA -->
                  <h3 class="card-title"><strong>Nome:</strong> {{ field.motorista }}</h3>
                  <h3 class="card-title"><strong>Data da cobranca:</strong> {{ field.data_rota | date:'dd/MM/yyyy' }}</h3>
                  <h3 class="card-title"><strong>Agendamentos:</strong> {{ field.intinerario }}</h3>
                  <h3 class="card-title"><strong>Valor da carga:</strong> R${{ field.valor }}</h3>
                  <h3 class="card-title"><strong>Cobranças:</strong> R$ {{ field.valor_cupom - field.valores_adiantados | number:'2.2-2'  }}</h3>
                </div>
      
                <div *ngSwitchCase="6"> <!-- INTINERARIO -->
                  <h3 class="card-title"><strong>Nome:</strong> {{ field.nome }}</h3>
                  <h3 class="card-title"><strong>Telefone:</strong> {{ field.telefone }}</h3>
                  <h3 class="card-title"><strong>Whatsapp:</strong> {{ field.whatsapp }}</h3>
                  <h3 class="card-title"><strong>Endereco:</strong> {{field.rua}},{{field.numero}}</h3>
                  <h3 class="card-title"><strong>Bairro:</strong> {{field.bairro}} </h3>
                  <h3 class="card-title"><strong>Sub bairro: </strong>  {{field.sub_bairro}} </h3>
                  <h3 class="card-title" *ngIf="field.motorista != null && currentUser.role != 2"><strong>Motorista:</strong> {{field.motorista }} </h3>
                  <h3 class="card-title" *ngIf="field.motorista != null && currentUser.role != 2"><strong>Dia do servico:</strong> {{ field.data_rota | date:'dd/MM/yyyy' }} </h3>
                  <h3 class="card-title">
                    <strong>
                      Status:
                    </strong> 
                    {{field.flag_pago == 1 ? 'Finalizado' : 'Pendente' }} </h3>
                </div>
              </div>
              <div class="card-footer border-0 bg-transparent align-bottom pt-0 pl-3">
                <a *ngIf="tipoEntradaDeDado != 6 && tipoEntradaDeDado != 5 && tipoEntradaDeDado != 4 && currentUser.role != 2 " [routerLink]="['form/'+field.id]" class="btn btn-danger ml-1 mt-1"><i class="bi bi-pencil"></i></a>
                <a *ngIf="tipoEntradaDeDado == 4 && !field.flag_pago && currentUser.role != 2" [routerLink]="['form/'+field.id]" class="btn btn-danger ml-1 mt-1"><i class="bi bi-pencil"></i></a>
                <a *ngIf="tipoEntradaDeDado == 5 && currentUser.role != 2" (click)="editarRota(field.id,field.data_rota,field.id_usuario)" class="btn btn-danger ml-1 mt-1"><i class="bi bi-pencil"></i></a>
                <a *ngIf="tipoEntradaDeDado != 6 && currentUser.role == 1" [hidden]="field.flag_pago" class="btn btn-danger ml-1 mt-1" (click)="exibirModal(field.id)"><i class="bi bi-trash"></i></a>
                <a *ngIf="tipoEntradaDeDado == 5" (click)="baixarRotaPeloId(field.id, field.motorista, field.data_rota);" class="btn btn-danger ml-1 mt-1"><i class="bi bi-printer"></i></a>
                <a *ngIf="tipoEntradaDeDado == 6 && field.flag_pago == 0" (click)="baixa(field.id_intinerario);" class="btn btn-danger ml-1 mt-1"><i class="bi bi-cart2"></i></a>
                <a *ngIf="(tipoEntradaDeDado == 4 || tipoEntradaDeDado == 6)" target="_blank" href="https://www.google.com/maps/place/{{field.rua}},+{{field.numero}}+-+{{field.nome_bairro}},+{{field.nome_cidade}}+-+RJ,+{{field.cep}}"  class="btn btn-danger ml-1 mt-1"><i class="bi bi-geo-alt-fill"></i></a>
                <a *ngIf="tipoEntradaDeDado == 4" href="https://wa.me/55{{field.whatsapp}}?text={{field.mensagem_wpp}}" target="_blank" class="btn btn-success ml-1 mt-1"><i class="bi bi-whatsapp"></i></a>
                <!-- <button *ngIf="tipoEntradaDeDado == 4" class="btn btn-danger  ml-1 mt-1" type="button" data-toggle="collapse" attr.data-target="#campo-{{field.id}}" aria-expanded="false" aria-controls="collapseExample">
                  <i class="bi bi-search"></i>
                </button> -->
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button type="button" (click)="adicionarCardsAoGrid();" class="btn btn-danger btn-lg" *ngIf="filtro.totalDeRegistros > (filtro.quantidadePorPargina * filtro.pagina)">Carregar mais {{ (filtro.totalDeRegistros - (filtro.quantidadePorPargina * filtro.pagina)) > 12 ? 12 : (filtro.totalDeRegistros - (filtro.quantidadePorPargina * filtro.pagina)) }} de {{ filtro.totalDeRegistros - (filtro.quantidadePorPargina * filtro.pagina) }}</button>
        </div>
      </div>
    </div>
  <!-- FIM DA LISTA -->
  </div>  
  <ng-template #empty>
    <div class="alert alert-warning mt-3" role="alert">
      Ops! Dados não encontrados
    </div>
      </ng-template>
</div>

<app-modal #modal *ngIf="modal" [mensagem]="mensagemModal" [exibir]="modal" (saida)="fecharModal($event);"></app-modal>