import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TipoOperacaoEnum } from 'src/app/enum/tipoOperacao';
import { TelaEnum } from 'src/app/enum/tipoTela';
import { Filtro } from 'src/app/model/filtro.model';
import { IntinerarioService } from 'src/app/service/intinerario.service';
import { FiltroData } from 'src/app/model/filtro-data.model';
import { UsuarioService } from 'src/app/service/usuario.service';
import { AuthService } from 'src/app/service/auth.service';
import { EnderecoService } from 'src/app/service/endereco.service';
import { Cliente } from 'src/app/model/cliente.model';
import { RotaService } from 'src/app/service/rota.service';

@Component({
  selector: 'app-intinerario-list',
  templateUrl: './intinerario-list.component.html',
  styleUrls: ['./intinerario-list.component.css']
})
export class IntinerarioListComponent implements OnInit {
  
  @Output() motoristas: Cliente[] = [];
  tela: TelaEnum = TelaEnum.intinerario;
  filtro: Filtro = new Filtro();
  intinerarios:any[] = [];
  intinerario:any =[];
  showOverlay:boolean = false;
  filtroData: FiltroData = new FiltroData();
  selecaoAgenda: any[] = [];
  count: number = 0;
  currentUser;
  cidades = [];
  agendar = false;

  constructor(
    private intinerarioService: IntinerarioService,
    private usuarioService: UsuarioService, 
    private enderecoService: EnderecoService,
    private router: Router, 
    private authService: AuthService,
    private rotaService: RotaService) {
    this.authService.currentUser.subscribe(x => this.currentUser = x); 
  }

  ngOnInit(): void {
    this.start();
  }

  async start(){
    this.showOverlay = true;
    await this.construirFiltro();
    await this.listarCidadesFiltro();
    var intinerarios = await this.intinerarioService.listar(this.filtro);
    this.intinerarios  = await this.montarAgrupamentoDosCards(intinerarios.value);
    await this.recuperarListaDeMotoristas();
    this.showOverlay = false;
  }

  async listarCidadesFiltro(){
    let cidades = await this.enderecoService.listarCidades();
    this.cidades = cidades.value;
  }

  reagendarIntinerario(id){
    this.intinerario = this.intinerarios.filter(x=> x.id == id);
    if(this.intinerario != undefined)
      this.agendar = true;
  }  

  async montarAgrupamentoDosCards(intinerarios){
    let grupos = [];
    
    if(intinerarios != undefined){
      if(intinerarios.length){
        if(intinerarios.length <=this.filtro.quantidadePorPargina){
          grupos.push(intinerarios);
          await this.montarListaDeSelecaoReagendamento(intinerarios);
        }
        else{
          for(let i = 1; i <= intinerarios.length; i++){
            if(i%this.filtro.quantidadePorPargina == 0){
              grupos.push(intinerarios.slice(i-this.filtro.quantidadePorPargina, i));
              await this.montarListaDeSelecaoReagendamento(intinerarios.slice(i-this.filtro.quantidadePorPargina, i));
              if(intinerarios.length - i <= this.filtro.quantidadePorPargina){
                grupos.push(intinerarios.slice(i, intinerarios.length));
                await this.montarListaDeSelecaoReagendamento(intinerarios.slice(i, intinerarios.length));
                break;
              }
            }
          }
        }
      }
    }
    return grupos;
  }

  async construirFiltro(){
    await this.intinerarioService.count(this.filtro).then( x =>{
      let count = x.value[0].count;

      if(this.currentUser.role == 2){
        this.filtro.dados.role = this.currentUser.role;
        this.filtro.dados.id_motorista = this.currentUser.id;
      }
      
      this.filtro.paginas = count <= this.filtro.quantidadePorPargina ? 1 : Math.ceil(count / this.filtro.quantidadePorPargina) ;
      this.filtro.totalDeRegistros =  count;
      this.count = count;
    });;
  }

  async recuperarListaDeMotoristas(){
    let retorno = await this.usuarioService.listarMotorista();
    this.motoristas = retorno.value;
  }

  async adicionarCards(filtro){
    this.showOverlay = true;
    let intinerarios = await this.intinerarioService.listar(filtro);
    let grupos = await this.montarAgrupamentoDosCards(intinerarios.value);
    this.intinerarios.push(grupos[0]);
    this.showOverlay = false;
  }

  async pesquisar(filtro:Filtro = null){
    this.showOverlay = true;
    await this.intinerarioService.count(filtro).then( x =>{
      let count = x.value[0].count;
      filtro.paginas = count <= this.filtro.quantidadePorPargina ? 1 : Math.ceil(count / this.filtro.quantidadePorPargina) ;
      filtro.totalDeRegistros =  count;
      filtro.paginas = 1;
      filtro.pagina = 1;
      this.count = count;
    });

    await this.intinerarioService.listar(filtro).then(async x => this.intinerarios = await this.montarAgrupamentoDosCards(x.value));

    this.filtro = filtro;
    this.showOverlay = false;
  }

  async agendarRemoverTodosOsIntinerariosDaTela(event){
    for(let intinerario of this.selecaoAgenda){
      if(event.id.find( x=> x == intinerario.id))
        intinerario.agendar = event.status;
    }
  }

  async agendarIntinerariosSelecionados(event){
    let existeAgendamento = this.selecaoAgenda.filter(x=> x.agendar);
    if(existeAgendamento.length)
      this.agendar = event;
  }

  adicionarAgendamento(event){
    let intinerario = this.selecaoAgenda.find(x=> x.id == event.id);
    if(intinerario != undefined)
      intinerario.agendar = event.agendar;
  }

  fecharModal(e){
    this.agendar = false;
    if(e.status) {
      this.showOverlay = true;
      this.rotaService
        .agendarRota(e.idMotorista, e.data, this.selecaoAgenda.filter (x=> x.agendar).map(x=> x.id)).then(params=>{
          this.router.navigate(['/intinerario']);
          this.filtro.pagina = 1;
          this.start();
        });
        this.showOverlay = false;
    }
  }

  montarListaDeSelecaoReagendamento(intinerario){
    if(intinerario!=undefined){
      if(intinerario.length){
        let lista = intinerario.filter(x=> x.flag_pago == 0  && x.valor > 0);
        if(lista != undefined){
          let ids = this.selecaoAgenda.map(x=> x.id);
          for(let inti of lista){
            if(!ids.includes(inti.id)){
              this.selecaoAgenda.push({ id: inti.id, agendar: false });
            }
          }
        }
      }
    }
  }

  async deletar(id){
    await this.intinerarioService.deletar(id).then(params=>{
      this.router.navigate(['/intinerario'],  { queryParams: { message: TipoOperacaoEnum.deletar.valueOf() }} );
      this.filtro.pagina = 1;
      this.start();
    });
  }
}
