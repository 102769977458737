import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Cliente } from 'src/app/model/cliente.model';

@Component({
  selector: 'app-cupom-agendamento',
  templateUrl: './cupom-agendamento.component.html',
  styleUrls: ['./cupom-agendamento.component.css']
})
export class CupomAgendamentoComponent {

  @Input() motoristas:Cliente[] = [];
  @Input() cupons:any[] = [];
  @Input() exibir:boolean = false;
  @Output() saida: EventEmitter<any> = new EventEmitter();
  idMotorista: Number = 0;
  dataIntinerario:string = '';
  validations:boolean = true;

  setDataIntenerario = (data) => this.dataIntinerario = data.target.value;

  setIdMotorista = (data) => this.idMotorista = data.target.value;
  
  async retorno(event){
    await this.validarFormulario();
    if(event){
      if(this.validations)
        this.saida.emit({status: event, idMotorista: this.idMotorista, data: this.dataIntinerario})
    } 
    else 
    {
      this.saida.emit({status: event})
    }
  }

  validarFormulario(){
    if(this.idMotorista != 0 && this.dataIntinerario.length != 0){
      this.validations = true;
    } else {
      this.validations = false;
    } 
  }
}
