import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private currentUserSubject: BehaviorSubject<any>
    public currentUser: Observable<any>

    constructor(private http: HttpClient) { 
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    login(login, senha) {
        return this.http.post<any>(`${environment.apiUrl}login`, {"login": login, "senha": senha})
        .pipe(map(data => {
            return this.setData(data);
        }));
    }

    setData(data): any{
        localStorage.setItem('currentUser', JSON.stringify(data.usuario));
        localStorage.setItem('id_token', JSON.stringify(data.id_token.replace('"', '')));
        this.currentUserSubject.next(data.usuario);
        return data.usuario;
    }

    logout(){
        localStorage.removeItem('currentUser');
        localStorage.removeItem('id_token');
        this.currentUserSubject.next(null);
    }
}