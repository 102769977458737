import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Cliente } from 'src/app/model/cliente.model';
import { Compra } from 'src/app/model/compra.model';
import { Cupom } from 'src/app/model/cupom.model';
import { Filtro } from 'src/app/model/filtro.model';
import { Produto } from 'src/app/model/produto.model';
import { ClienteService } from 'src/app/service/cliente.service';
import { CupomService } from 'src/app/service/cupom.service';
import { ProdutoService } from 'src/app/service/produto.service';
import * as moment from 'moment';
import { FiltroData } from 'src/app/model/filtro-data.model';
import { AuthService } from 'src/app/service/auth.service';
import { Incorporacao } from 'src/app/model/incorporacao.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-cupom-form',
  templateUrl: './cupom-form.component.html',
  styleUrls: ['./cupom-form.component.css']
})
export class CupomFormComponent implements OnInit, OnDestroy {

  validations:boolean = true;
  cupom: Cupom = new Cupom();
  cliente: Cliente[] = [];
  id:number;
  showOverlay:boolean = false;
  filtro: Filtro = new Filtro();
  produtosComprados: Compra[] = [];
  countCliente: number;
  countProduto: number;
  valorDaCompra:number = 0;
  nomeCliente = '';
  administrador: boolean = true;
  currentUser;
  bloqueio:boolean = undefined;
  multiplicador:number = 1;
  exibir:boolean = false;
  dadosIncorporacao: Incorporacao = new Incorporacao();
  exibirIncorporacao: boolean = false;
  valorIncorporacao: number = 0;
  valor: number = 0;
  cupomAberto:boolean = false;
  exibirCupomAberto:boolean = false;
  quantidadePaga:number = 0;
  subject: Subject<any> = new Subject();

  constructor(
    private cupomService: CupomService, private clienteService: ClienteService,
    private produtoService: ProdutoService, private router: Router, 
    private route: ActivatedRoute, private authService: AuthService) {
      this.authService.currentUser.subscribe(x => this.currentUser = x);  
      this.administrador = this.currentUser.role == 1;
  }
  
  ngOnDestroy(): void {
    this.subject.unsubscribe();
  }

  async ngOnInit() {
    this.showOverlay = true;
    this.filtro.dados = new FiltroData();
    
    await this.construirFiltroDeConsulta();
    await this.identificarOperacao();

    this.showOverlay = false;
  }

  async identificarOperacao(){
    this.id = this.route.snapshot.params['id'];
    await this.montarDataListProduto();

    if(this.id != undefined){
      this.exibirCupomAberto = false;
      var cupom = await this.cupomService.encontrarPeloId(this.id);
      this.alimentarFormularioDeEdicao(cupom);
    }
    else
    {
      this.subject
      .pipe(debounceTime(1500))
      .subscribe(async () => {
        await this.montarDataListCliente();
      });
    }
    
    this.bloqueio = this.produtosComprados.length > 0;
  }

  pesquisarCliente(teste){
    this.filtro.dados.palavraChave = teste;
    this.subject.next();
  }

  sincronizarEventos(e){
    this.cupomAberto = e.target.checked;
  }

  async alimentarFormularioDeEdicao(cupom){
    this.quantidadePaga = parseFloat(cupom.cupom.valor_adiantado);
    this.cupom.id = cupom.cupom.id;
    this.cupom.data_cobranca = cupom.cupom.data_cobranca == null ? '' : moment(cupom.cupom.data_cobranca).format("yyyy-MM-DD");
    this.cupom.flag_pago = cupom.cupom.flag_pago;
    this.cupom.id_cliente = cupom.cliente.id;
    this.nomeCliente = cupom.cliente.nome;
    this.cupom.valor = cupom.cupom.valor;
    this.cupom.valor_adiantado = cupom.cupom.valor_adiantado;
    this.cupom.valor_pago = cupom.cupom.valor_pago;
    this.cupom.flag_pago = cupom.cupom.flag_pago;
    this.cupom.flag_repasse = cupom.cupom.flag_repasse;

    if(this.cupom.flag_repasse){
      this.valorDaCompra = this.cupom.valor;
    }

    if(this.administrador){
      this.construirInputsProdutosAdministrador(cupom.produtos);
    }else{
      this.construirInputsProdutosMotorista(cupom.produtos);
    }

    for(let produto of cupom.produtos){
      for(let i = 0; i < produto.quantidade; i++){
        this.adiconarProduto(produto.id)
      }
    }

    if(cupom.incorporacao.length && !cupom.flag_pago){
      let incorp = await this.cupomService.obterSaldoResidualCupom(this.cupom.id);
      this.dadosIncorporacao.valor =  incorp[0].valor * (-1.0);
      this.dadosIncorporacao.valor_utilizado = incorp[0].valor * (-1.0);
      this.exibirIncorporacao = true;
      this.incorporarOValor();
    }
  }

  construirInputsProdutosAdministrador(produto:Produto[]){
    produto.forEach(x=>{
      let compra = new Compra();
      compra.id = x.id;
      compra.preco = x.preco;
      compra.nome_pai = x.nome_pai;
      compra.nome_filho = x.nome_filho;
      compra.lote = x.lote;
      this.produtosComprados.push(compra);
    });
  }

  construirInputsProdutosMotorista(produto:any[]){
    if(produto != undefined){
      produto.forEach(x=>{
        let entrada = x.entrada == null ? 0 : x.entrada;
        let saida = x.saida == null ? 0 : x.saida; 
        let devolucao = x.devolucao == null ? 0 : x.devolucao;

        if((entrada + devolucao) > saida){
          let compra = new Compra();
          compra.id = x.id;
          compra.preco = x.preco;
          compra.nome_pai = x.nome_pai;
          compra.nome_filho = x.nome_filho;
          compra.lote = x.lote;
          compra.limiteDeDelucao = (entrada + devolucao) - saida;
          this.produtosComprados.push(compra);
        }
      });
    }
  }

  construirFiltroDeConsulta() {
    this.filtro.quantidadePorPargina = 10;
    this.filtro.pagina = 1;
  }

  async montarDataListCliente() {
    this.filtro.paginas = this.countCliente / 2;

    var clientes = await this.clienteService.listar(this.filtro);
    this.cliente = clientes.value;
  }

  async montarDataListProduto(palavraChave = '') {
    var produtos; 
    this.filtro.quantidadePorPargina = 10000;
    this.filtro.paginas = 1;
    this.filtro.dados.palavraChave = palavraChave;
    
    if(this.administrador){
      produtos = await this.produtoService.listar(this.filtro);
      this.construirInputsProdutosAdministrador(produtos.value);
    }else{
      produtos = await this.produtoService.listarProdutosDaCargaPeloMotorista(this.currentUser.id);
      this.construirInputsProdutosMotorista(produtos.value);
    }

  }

  adiconarProduto(id){
    let produto = this.produtosComprados.find(x=> x.id == id);
    if(produto.limiteDeDelucao <= produto.quantidade && this.administrador){

      if(produto == undefined) produto = new Compra();
      
      if(produto.lotear){
        if((produto.quantidade + produto.lote) > produto.limiteDeDelucao){
          if(produto.quantidade == 0){
            produto.quantidade += produto.lote;
            produto.precoTotal = produto.preco * produto.quantidade 
          }
          else
          {
            produto.quantidade = (Math.round(produto.quantidade/ produto.lote) + 1) * produto.lote;
            produto.precoTotal = produto.preco * produto.quantidade 
          }
        }
        else
        {
          if((produto.quantidade + produto.lote) <= produto.lote)
          {
            produto.quantidade += produto.lote;
            produto.precoTotal = produto.preco * produto.lote;
          }
          else
          {
            produto.quantidade = (Math.round(produto.quantidade/ produto.lote) + 1) * produto.lote;
            produto.precoTotal = produto.preco * produto.lote;
          }
        }
      } else {
        produto.quantidade += 1;
        produto.precoTotal += produto.preco
      }
    }
    else{
      if(produto.quantidade < produto.limiteDeDelucao){
        if(produto.lotear){
          if(produto.limiteDeDelucao <= produto.lote){
            produto.quantidade = produto.limiteDeDelucao;
            produto.precoTotal = produto.preco *  produto.limiteDeDelucao;
          }
          else
          {
            produto.quantidade += produto.lote;
            produto.precoTotal = produto.preco *  produto.lote;
          }
        }
        else{
          if(produto.limiteDeDelucao > produto.quantidade){
            produto.quantidade += 1;
            produto.precoTotal += produto.preco
          }
        }
      }
    }
    this.calcularPrecoTotal();
    this.calcularTotal();
  }

  removerProduto(id){
    let produto = this.produtosComprados.find(x=> x.id == id);
    
    if(produto.quantidade > 0){
      if(produto.lotear) {
        if((produto.quantidade - (1 * produto.lote)) > 0){
          produto.quantidade -= (1 * produto.lote);
          produto.precoTotal -= (produto.preco * produto.lote);
        }else{
          produto.quantidade = 0;
          produto.precoTotal = 0;
        }
      } else {
        produto.quantidade -= 1;
        produto.precoTotal -= produto.preco;
      }
    }

    this.calcularPrecoTotal();
    this.calcularTotal();

    if(this.valorDaCompra == 0){
      if(this.dadosIncorporacao.utilizado){
        this.removerIncorporacao();
      }
    }
  }

  lotearVenda(id, lote){
    let produtos = this.produtosComprados.find(x=> x.id == id);
    produtos.lotear = lote != 1 && !produtos.lotear;
  }


  async loadCliente(idCliente){
    let saldo = await this.cupomService.obterSaldoResidual(idCliente);
    if(saldo[0].valor != 0 && !this.cupom.flag_pago){
      this.dadosIncorporacao.valor += (saldo[0].valor + this.dadosIncorporacao.valor_utilizado);
      this.exibirIncorporacao = true;
    }
  }

  async setCliente(cliente){
    this.showOverlay = true;
    this.exibirIncorporacao = false;
    this.removerIncorporacao();
    let find = this.cliente.filter(x=> x.nome == cliente.target.value);

    if(!find.length){
      this.cupom.id_cliente = 0;
      this.showOverlay = false;
      this.validations = false
    }
    else
    {
      let id = this.cliente.find(x=> x.nome == cliente.target.value).id;
      let saldo = await this.cupomService.obterSaldoResidual(id); 
      let check = await this.cupomService.checarSePossuiCupomAberto(id);
      this.exibirCupomAberto = check.value[0].valor >= 1
      
      if(saldo[0].valor != 0){
        this.dadosIncorporacao.valor = saldo[0].valor * (-1.0);
        this.exibirIncorporacao = true;
      }
  
      this.cupom.id_cliente = id;
      this.showOverlay = false;
    }
  }

  setValorAdiantado(data){
    if(data.target.value == '') data.target.value = '0';

    this.cupom.valor_adiantado = data.target.value.toString().replace(`,`, `.`);
    this.quantidadePaga = parseFloat(this.cupom.valor_adiantado);
    this.calcularPrecoTotal();
    this.calcularTotal();
  } 

  setDataCobranca(data){
    this.cupom.data_cobranca = data.target.value;
  } 

  calcularPrecoTotal(){
    let precoFinal = this.produtosComprados.reduce((precoTotal, item) => precoTotal + item.precoTotal, 0);
    let valor = precoFinal.toFixed(2).toString();
    
    if(this.cupom.flag_repasse) 
    {
      this.valorDaCompra = parseFloat(valor) < 0 ? 0 : parseFloat(valor) + this.cupom.valor;
    }
    else
    {
      this.valorDaCompra = parseFloat(valor) < 0 ? 0 : parseFloat(valor);
    }
    
  }

  calcularTotal(){
    let precoFinal = this.produtosComprados.reduce((precoTotal, item) => precoTotal + item.precoTotal, 0);
    let a = this.dadosIncorporacao.valor + this.dadosIncorporacao.valor_utilizado;

    if(this.dadosIncorporacao.utilizado){
      if(a == 0){
        this.valorIncorporacao = this.dadosIncorporacao.valor;
      }
      else
      {
        this.valorIncorporacao = a;
      }
    }
    this.valor = precoFinal - (parseFloat(this.cupom.valor_adiantado) + (this.dadosIncorporacao.valor * (-1.0)));
  }

  async incorporarOValor(){
    let residuoAtual = this.valorDaCompra == 0 && this.dadosIncorporacao.valor > 0 ? this.valorDaCompra : this.dadosIncorporacao.valor * -(1.0),
        incorporacao = this.valorDaCompra == 0 && this.dadosIncorporacao.valor > 0 ? this.valorDaCompra : this.valorDaCompra + residuoAtual;

    if(incorporacao > 0){
      this.valorIncorporacao = residuoAtual;
      this.dadosIncorporacao.valor_utilizado = residuoAtual;
      this.dadosIncorporacao.utilizado = true;
    } 
    else
    {
      this.valorIncorporacao = incorporacao;
      this.dadosIncorporacao.valor_utilizado = this.valorDaCompra == 0 ? this.valorDaCompra : this.valorIncorporacao;
      this.dadosIncorporacao.utilizado = this.valorDaCompra != 0 ;
    }
    
    this.calcularPrecoTotal();
    this.calcularTotal();
  }

  removerIncorporacao() {
    if(this.id != undefined){
      this.valorIncorporacao = 0;
      this.dadosIncorporacao.valor = this.dadosIncorporacao.valor_utilizado * -(1.0);
      this.dadosIncorporacao.valor_utilizado = 0;
    }
    else
    {
      this.valorIncorporacao = 0;
      this.dadosIncorporacao.valor_utilizado = 0;
    }

    this.dadosIncorporacao.utilizado = false;
    this.calcularPrecoTotal();
    this.calcularTotal();
  }

  calculoDoCupom(): boolean{
    let pago = parseFloat(this.cupom.valor_adiantado.toString().replace(`,`, `.`));
    return  pago > this.cupom.valor;
  }

  validarFormulario(){
    this.validations = this.cupom.id_cliente != 0;
  }

  validarESalvar(){
    this.validarFormulario();

    if(!this.validations) return;
    
    if(this.cupom.flag_repasse)
      this.cupom.valor = this.valorDaCompra;
    else  
      this.cupom.valor = this.produtosComprados.reduce((precoTotal, item) => precoTotal + item.precoTotal, 0);

    this.cupom.incorporacao = this.dadosIncorporacao;

    if(this.dadosIncorporacao.valor > 0)
      this.cupom.flag_pago = parseFloat(this.cupom.valor_adiantado) + (this.dadosIncorporacao.valor_utilizado * (-1.0)) >= this.cupom.valor;
    else
    {
      this.cupom.flag_pago = false;
      this.cupom.valor += this.dadosIncorporacao.valor_utilizado
    }

    if(this.cupom.data_cobranca == '')
      this.cupom.data_cobranca = moment(new Date).format("yyyy-MM-DD")

    if(parseFloat(this.cupom.valor_adiantado) != this.cupom.valor) 
      this.calculoDoCupom();

    if(this.id > 0 )
      this.editar({ cupom: this.cupom, role: this.currentUser.role, produtos: this.produtosComprados, idUsuario: this.currentUser.id });
    else
      this.salvar({ cupom: this.cupom, role: this.currentUser.role, produtos: this.produtosComprados, idUsuario: this.currentUser.id, aberto: this.cupomAberto });
  }

  async editar(data){
    this.showOverlay = true;
    await this.cupomService.editar(data)
    .then(x=>{
      this.showOverlay = false;
      this.router.navigate(['/cupom'],  { queryParams: { message: 2 }} );
    })
    .catch(x=>{
      this.showOverlay = false;
      this.router.navigate(['/cupom'],  { queryParams: { message: 0 }} );
    });
  }

  async salvar(data){
    this.cupom.created_by = this.currentUser.id;
    this.showOverlay = true;
    this.cupomService.criar(data)
    .then(x=>{
      this.showOverlay = false;
      this.router.navigate(['/cupom'],  { queryParams: { message: 1 }} );
    })
    .catch(x=>{
      this.showOverlay = false;
      this.router.navigate(['/cupom'],  { queryParams: { message: 0 }} );
    });
  }
}
