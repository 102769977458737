import { Compra } from "./compra.model";

export class CardCupom {
    id: number = 0;
    id_cliente:number = 0;
    nome_cliente:string = '';
    cpf:string = '';
    rua: string = '';
    numero: string = '';
    nome_bairro: string = '';
    nome_cidade: string = '';
    data_cobranca:string = '';
    data_vencimento:string = '';
    valor:number = 0;
    valor_adiantado:string = '0';
    valor_pago:string = '0';
    flag_pago: boolean = false;
    flag_repasse: boolean = false;
    Produtos: Compra[];
    motorista:string= '';
    data_rota:string= '';
    id_cidade: string = '';
    whatsapp:string = '';
    id_bairro: string = '';
    mensagem_wpp: string = '';
}