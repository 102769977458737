import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        const currentUser = this.authService.currentUserValue;


        if(currentUser == null){
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }

        if(currentUser.role == 2){
            if(!(route.routeConfig.path == '' || route.routeConfig.path == 'cliente' || route.routeConfig.path == 'cliente/form' || route.routeConfig.path == 'cupom' || route.routeConfig.path == 'cupom/form' || route.routeConfig.path == 'intinerario' || route.routeConfig.path == 'intinerario/baixa/:id')){
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
                return false;
            }
        }
        
        return true;
    }
}