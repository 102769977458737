<div class="modal d-block" tabindex="-1" >
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title" *ngIf="!painel">Alerta</span>
        <span class="modal-title" *ngIf="painel">Deseja salvar esse cliente?</span>
      </div>
      <div class="modal-body" *ngIf="!painel">
        <p>{{ mensagem }}</p>
      </div>
      <div class="modal-body" *ngIf="painel">
        <div class="mb-3">
          <label><strong>Nome Completo:</strong></label>
          <p>{{ cliente.nome }}</p>
        </div>
        <div class="mb-3">
          <label><strong>CPF:</strong></label>
          <p>{{ cliente.cpf }}</p>
        </div>
        <div class="mb-3">
          <label><strong>Telefone:</strong></label>
          <p>{{ cliente.telefone }}</p>
        </div>
        <div class="mb-3">
          <label><strong>Whatsapp:</strong></label>
          <p>{{ cliente.whatsapp }}</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="retorno(false);">Nao</button>
        <button type="button" class="btn btn-danger" (click)="retorno(true);">Sim</button>
      </div>
    </div>
  </div>
</div>
<div class="my-overlay" *ngIf="exibir" style="z-index: 99 !important;"></div>