import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-intinerario-repasse',
  templateUrl: './intinerario-repasse.component.html',
  styleUrls: ['./intinerario-repasse.component.css']
})

export class IntinerarioRepasseComponent {

  @Input() exibir:boolean = true;
  @Input() valor;
  dataAgendamento: string = '';
  disableButton = true;
  @Output() saida: EventEmitter<any> = new EventEmitter();
  
  setDataAgendamento = (data) => {
    if(data.target.value != ''){
      this.dataAgendamento = data.target.value;
      this.disableButton = false;
    }
    else
    {
      this.disableButton = true;
    }
  }

  retorno = (event) => this.saida.emit({ event: event, data: this.dataAgendamento})
}
