export class Compra {
    id: number;
    preco:number = 0;
    quantidade:number = 0;
    lote:number = 0;
    limiteDeDelucao = 0;
    precoTotal:number = 0;
    nome_pai: string;
    nome_filho:string;
    id_filho:string;
    lotear: boolean = false;
    flag_devolucao: boolean = false;
}