import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { UsuarioListComponent } from './usuario/usuario-list/usuario-list.component';
import { UsuarioFormComponent } from './usuario/usuario-form/usuario-form.component';
import { ClienteListComponent } from './cliente/cliente-list/cliente-list.component';
import { ClienteFormComponent } from './cliente/cliente-form/cliente-form.component';
import { ProdutoListComponent } from './produto/produto-list/produto-list.component';
import { ProdutoFormComponent } from './produto/produto-form/produto-form.component';
import { RotaListComponent } from './rota/rota-list/rota-list.component';
import { RotaFormComponent } from './rota/rota-form/rota-form.component';
import { CupomListComponent } from './cupom/cupom-list/cupom-list.component';
import { CupomFormComponent } from './cupom/cupom-form/cupom-form.component';
import { SharedModule } from '../shared/shared.module';
import { IntinerarioListComponent } from './intinerario/intinerario-list/intinerario-list.component';
import { IntinerarioFormComponent } from './intinerario/intinerario-form/intinerario-form.component';
import { IntinerarioAgendamentoComponent } from './intinerario/intinerario-agendamento/intinerario-agendamento.component';
import { CupomAgendamentoComponent } from './cupom/cupom-agendamento/cupom-agendamento.component';
import { IntinerarioRepasseComponent } from './intinerario/intinerario-repasse/intinerario-repasse.component';
import { CupomValorResidualComponent } from './cupom/cupom-valor-residual/cupom-valor-residual.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule
  ],
  declarations: [ 
    LoginComponent,
    HomeComponent,
    UsuarioListComponent, 
    UsuarioFormComponent, 
    ClienteListComponent, 
    ClienteFormComponent, 
    ProdutoListComponent,
    ProdutoFormComponent, 
    RotaListComponent,
    RotaFormComponent,
    CupomListComponent,
    CupomFormComponent,
    IntinerarioListComponent,
    IntinerarioFormComponent,
    IntinerarioAgendamentoComponent,
    CupomAgendamentoComponent,
    IntinerarioRepasseComponent,
    CupomValorResidualComponent
  ],
  exports:[
    LoginComponent,
    HomeComponent,
    UsuarioListComponent, 
    UsuarioFormComponent, 
    ClienteListComponent, 
    ClienteFormComponent, 
    ProdutoListComponent,
    ProdutoFormComponent, 
    RotaListComponent,
    RotaFormComponent,
    CupomListComponent,
    CupomFormComponent,
    IntinerarioAgendamentoComponent
  ]  
})
export class PagesModule { }
