import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { FilterComponent } from './content/filter/filter.component';
import { ListComponent } from './content/list/list.component';
import { ModalComponent } from './content/modal/modal.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    FooterComponent,
    ListComponent,
    ModalComponent,
    FilterComponent
  ],
  imports: [
    CommonModule, 
    NgbModule,
    RouterModule
  ],
  exports:[
    FooterComponent,
    ListComponent,
    ModalComponent,
    FilterComponent
  ]
})

export class SharedModule { }
