import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Filtro } from "../model/filtro.model";


export abstract class BaseService {

    constructor() { }

    public abstract listar(filtro: Filtro): Promise<any>;
    public abstract criar(usuario): Promise<any>;
    public abstract deletar(id): Promise<any> ;
    public abstract editar(usuario): Promise<any>;
    public abstract encontrarPeloId(id): Promise<any>;
    public abstract count(filtro: Filtro): Promise<any>;

    public createAHeader(){
        const token = JSON.parse(localStorage.getItem('id_token'));
        if(token){
            return new HttpHeaders({
                Authorization: 'Bearer ' + token
            })
        }
        return null;
    }
}