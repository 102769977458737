<div class="my-overlay" *ngIf="showOverlay">
    <div class="spinner-border m-5" role="status" style="color: white;" *ngIf="showOverlay">
      <span class="visually-hidden"></span>
    </div>
</div>
<app-list 
(pesquisar)="pesquisar($event);" 
(adicionarCards)="adicionarCards($event)" 
(deletar)="deletar($event);" 
[data]="clientes" 
[filtro]="filtro" 
[cidades]="cidades" 
[tipoEntradaDeDado]="tela"></app-list>