import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Cliente } from 'src/app/model/cliente.model';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input() mensagem:string = '';
  @Input() painel:boolean = false;
  @Input() exibir:boolean = false;
  @Input() cliente:Cliente;
  @Input() cidadeBairro:string = '';
  @Output() saida: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  retorno(saida){
    this.saida.emit(saida);
    this.exibir = false;
  }
}
