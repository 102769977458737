<div class="container mr-5 ml-5 mx-auto login-box">
  <img src="../../../assets/logo.png" alt="img-logo" style=" min-width: 200px;" class="rounded mx-auto d-block w-25 mt-5 mb-5" />

  <div class="card">
    <div class="card-body">
      <div class="alert alert-danger mt-2" style="display: none;" id="notificacao">Usuario ou senha invalidos.</div>
      <form class="" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="login">Login</label>
          <input type="email" class="form-control" formControlName="login" id="login" aria-describedby="emailHelp" placeholder="Login de acesso">
          <small id="emailHelp" class="form-text text-muted">Nunca compartilhe seus dados de acesso com ninguem.</small>
        </div>
        <div class="form-group">
          <label for="senha">Senha</label>
          <input type="password" class="form-control" formControlName="senha" id="senha" placeholder="******">
        </div>
        <button type="submit" class="btn btn-danger">Acessar</button>
      </form>
    </div>
  </div>
</div>
   