import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TipoOperacaoEnum } from 'src/app/enum/tipoOperacao';
import { TelaEnum } from 'src/app/enum/tipoTela';
import { Filtro } from 'src/app/model/filtro.model';
import { RotaService } from 'src/app/service/rota.service';
import { Intinerario } from 'src/app/model/intinerario.mode';
import * as moment from 'moment';
import { FiltroData } from 'src/app/model/filtro-data.model';
import { UsuarioService } from 'src/app/service/usuario.service';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable'

@Component({
  selector: 'app-rota-list',
  templateUrl: './rota-list.component.html',
  styleUrls: ['./rota-list.component.css']
})
export class RotaListComponent implements OnInit {
  
  tela: TelaEnum = TelaEnum.rota;
  filtro:Filtro = new Filtro();
  rotas: any[] = [];
  showOverlay:boolean = false;
  filtroData:FiltroData;
  esconderBotaoPaginacao = false;
  motoristas = [];
  count: number = 0;

  constructor(private rotaService: RotaService,private usuarioService: UsuarioService, private router: Router) { }

  async ngOnInit() {
    await this.start();
  }

  async start(){
    this.showOverlay = true;
    await this.construirFiltro();
    var rota = await this.rotaService.listar(this.filtro);
    this.rotas = await this.montarAgrupamentoDosCards(rota.value);
    await this.recuperarListaDeMotoristas();
    this.showOverlay = false;
  }

  async montarAgrupamentoDosCards(rotas){
    let grupos = [];

    if(rotas != undefined){
      if(rotas.length){
        if(rotas.length <= this.filtro.quantidadePorPargina){
          grupos.push(rotas);
        }
        else{
          for(let i = 1; i <= rotas.length; i++){
            if(i % this.filtro.quantidadePorPargina == 0){
              grupos.push(rotas.slice(i- this.filtro.quantidadePorPargina, i));
              if(rotas.length - i <= this.filtro.quantidadePorPargina){
                grupos.push(rotas.slice(i, rotas.length));
                break;
              }
            }
          }
        }
      }
    }
    return grupos;
  }
  
  async construirFiltro(){
    await this.rotaService.count(this.filtro).then( x =>{
      let count = x.value[0].count;
      
      this.filtro.paginas = count <= this.filtro.quantidadePorPargina ? 1 : Math.ceil(count / this.filtro.quantidadePorPargina) ;
      this.filtro.totalDeRegistros =  count;
      this.count = count;
    });
  }

  async adicionarCards(filtro){
    this.showOverlay = true;
    let rotas = await this.rotaService.listar(filtro);
    let grupos = await this.montarAgrupamentoDosCards(rotas.value);
    this.rotas.push(grupos[0]);
    this.showOverlay = false;
  }

  async recuperarListaDeMotoristas(){
    let retorno = await this.usuarioService.listarMotorista();
    this.motoristas = retorno.value;
  }

  async pesquisar(filtro:Filtro = null){
    this.showOverlay = true;
    await this.rotaService.count(filtro).then( x =>{
      let count = x.value[0].count;
      filtro.paginas = count <= this.filtro.quantidadePorPargina ? 1 : Math.ceil(count / this.filtro.quantidadePorPargina) ;
      filtro.totalDeRegistros =  count;
      filtro.paginas = 1;
      filtro.pagina = 1;
      this.count = count;
    });

    await this.rotaService.listar(filtro).then(async x => this.rotas = await this.montarAgrupamentoDosCards(x.value));
    this.filtro = filtro;
    this.showOverlay = false;
  }

  async deletar(id){
    await this.rotaService.deletar(id).then(params=>{
      this.router.navigate(['/rota'],  { queryParams: { message: TipoOperacaoEnum.deletar.valueOf() }} );
      this.filtro.pagina = 1;
      this.start();
    });
  }

  async baixarRotaDoDia(pacote){
    var rota: Intinerario[] = await this.rotaService.encontrarRotaPeloIdIntinerario(pacote);
    var produtos: any = await this.rotaService.rotaProduto(pacote.id);
  
    var doc:any = new jsPDF('l'); 
    var rowsContent = [];
    var rowsContentProduct = [];
    var rowsHeader = [];
    var rowsResumeProduct = [];
    var rowsResume = [];
    var finalValueProduct = 0;
    var finalValue = 0;
    var finalValueProducta = [];
    var finalValuea = [];
    rowsHeader = [[pacote.nome, moment(pacote.data).format("DD/MM/yyyy")]];
    doc.autoTable(["Motorista", "Data da cobranca"], rowsHeader);
    
    if(produtos.value.length){
      produtos.value.forEach(ceil => {
        finalValueProduct += (ceil['saida'] == null ? 0 : ceil['saida'])  * parseFloat(ceil[`preco`]);
        rowsContentProduct.push([(ceil[`nome_filho`] == null || ceil[`nome_filho`] == undefined ? ceil[`nome_pai`] : ceil[`nome_filho`]) ,'R$ '+ parseFloat(ceil[`preco`]).toFixed(2) ,(ceil['entrada'] == null ? 0 : ceil['entrada']),(ceil['saida'] == null ? 0 : ceil['saida']),(ceil['devolucao'] == null ? 0 : ceil['devolucao']), (((ceil['entrada'] == null ? 0 : ceil['entrada']) + (ceil['devolucao'] == null ? 0 : ceil['devolucao']) - (ceil['saida'] == null ? 0 : ceil['saida']))), `R$ `+ ((ceil['saida'] == null ? 0 : ceil['saida'])  * parseFloat(ceil[`preco`])).toFixed(2).toString() ])
      });
      doc.autoTable(['Produto', 'Preço', 'Entrada','Saída', 'Devolução', 'Restante', 'Vendido'], rowsContentProduct);
      finalValueProducta.push(finalValueProduct)
      rowsResumeProduct.push(finalValueProducta);
      doc.autoTable(['Valor Total dos produtos'], rowsResumeProduct);

    }

    if(rota.length){
      rota.forEach(ceil =>{
        finalValue +=  parseFloat(ceil['valor_pago']);
        rowsContent.push([ceil[`nome`],ceil[`telefone`],ceil[`whatsapp`],ceil[`rua`],ceil[`numero`], ceil[`complemento`],ceil[`sub_bairro`],ceil[`bairro`],ceil[`cidade`],'R$ '+ (ceil[`valor`] - ceil['valor_adiantado']), ceil['valor_pago'] ])
      });
      doc.autoTable(["Cliente", "Telefone", "Whatsapp", "Rua", "Numero", "Complemento", "Sub Bairro", "bairro", "Cidade", "Valor Total","Valor Pago"], rowsContent);
      finalValuea.push(finalValue)
      rowsResume.push(finalValuea);
      doc.autoTable(['Valor total pago pelos clientes'], rowsResume);

    }
    doc.save(`${pacote.nome}_${moment(pacote.data).format("DD-MM-yyyy")}.pdf`);
  }
}
