<div class="container pl-1 pr-1">
  <div class="card container mt-2 mb-2 mr-1">
    <div class="card- p-0 pt-2 pb-2">
      
      <div class="row  no-gutters">
        <div class="col-2 col-sm-2 col-lg-2 d-flex justify-content-start">
          <a *ngIf="tipoEntradaDeDado != 6" [routerLink]="[url]" class="btn btn-danger ml-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
              <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
            </svg>
          </a>
        </div>
        <div class="col-8 col-sm-8 col-lg-8 d-flex pt-2">
         <span class="w-100 text-center" style="font-size: 20px;">{{ titulo }}</span>
        </div>
        <div *ngIf="currentUser.role != 2" class="col-2 col-sm-2 col-lg-2 d-flex justify-content-end">
          <a class="btn btn-secondary" (click)="collapse(!isCollapsed);" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            <i class="bi bi-funnel"></i>
          </a>
        </div>
      </div>
      
      <div id="collapseExample" [ngbCollapse]="!isCollapsed"  [ngSwitch]="tipoEntradaDeDado">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-lg-4" *ngIf="tipoEntradaDeDado == 5 || tipoEntradaDeDado == 6">
              <div class="form-group">
                <label>Motorista</label>
                <select class="form-control" id="motorista">
                  <option value="">Todos</option>
                  <option *ngFor="let motorista of motoristas" value="{{motorista.id}}">{{ motorista.nome }}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4" *ngIf="tipoEntradaDeDado != 5 && tipoEntradaDeDado != 6">
              <div class="form-group">
                <label>Nome</label>
                <input type="text" id="palavraChave" class="form-control">
              </div>
            </div>
            <div class="col-sm-12 col-lg-4" *ngIf="tipoEntradaDeDado == 3">
              <div class="form-group">
                <label>CPF</label>
                <input type="number" id="CPF" class="form-control">
              </div>
            </div>
            <div class="col-sm-12 col-lg-4" *ngIf="tipoEntradaDeDado == 4 || tipoEntradaDeDado == 5 || tipoEntradaDeDado == 6">
              <div class="form-group">
                <label>Data de:</label>
                <input type="date" id="dataDe" class="form-control" placeholder="DD/MM/YYYY" value="{{ filtro.dados.dataDe }}">
              </div>
            </div>
            <div class="col-sm-12 col-lg-4" *ngIf="tipoEntradaDeDado == 4 || tipoEntradaDeDado == 5 || tipoEntradaDeDado == 6">
              <div class="form-group">
                <label>Ate:</label>
                <input type="date" id="dataAte" class="form-control" placeholder="DD/MM/YYYY" value="{{filtro.dados.dataAte}}">
              </div>
            </div>
            <div class="col-sm-12 col-lg-4" *ngIf="tipoEntradaDeDado == 4">
              <div class="form-group">
                <label>Status</label>
                <select class="form-control" id="status" >
                  <option value="">Todos</option>
                  <option value="1">Adimplente</option>
                  <option value="0">Inadimplente</option>
                </select>        
              </div>
            </div>
            <div class="col-sm-12 col-lg-4" *ngIf="tipoEntradaDeDado == 3">
              <div class="form-group">
                <label>Situação do cliente</label>
                <select class="form-control"  id="situacao">
                  <option value="">Todos</option>
                  <option value="=">Neutro</option>
                  <option value="<">Positivo</option>
                  <option value=">">Negativo</option>
                </select>        
              </div>
            </div>
            <div class="col-sm-12 col-lg-4" *ngIf="tipoEntradaDeDado == 3 || tipoEntradaDeDado == 4 || tipoEntradaDeDado == 6">
              <div class="form-group">
                <label>Cidade</label>
                <select class="form-control" id="cidade" (change)="carregarBairro($event);">
                  <option value="0">Selecione</option>
                  <option *ngFor="let cidade of cidades" value="{{cidade.id}}">{{cidade.nome}}</option>
                </select>        
              </div>
            </div>
            <div class="col-sm-12 col-lg-4" *ngIf="tipoEntradaDeDado == 3 || tipoEntradaDeDado == 4 || tipoEntradaDeDado == 6">
              <div class="form-group">
                <label>Bairro</label>
                <select class="form-control" id="bairro">
                  <option value="0">Selecione</option>
                  <option *ngFor="let bairro of bairros" value="{{bairro.id}}">{{bairro.nome}}</option>
                </select>        
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-danger ml-2" (click)="enviarPesquisa();">Pesquisar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row  mt-2 mb-2" *ngIf="currentUser.role != 2">
    <div class="col-12 d-flex justify-content-end" *ngIf="(tipoEntradaDeDado == 4 || tipoEntradaDeDado == 6) && cupomAgendamento">
      <button type="button" class="btn btn-secondary" (click)="agendarRemoverTodosOsCuponsDaTela();">{{ !agendar ? 'Marcar' : 'Desmarcar'}} todos</button>
      <button type="button" class="btn btn-danger ml-2" (click)="agendarCuponsSelecionados();">Agendar</button>
    </div>
  </div>
</div>