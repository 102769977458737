import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Filtro } from '../model/filtro.model';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class RotaService extends BaseService{

    private headers: HttpHeaders;

    constructor(private http: HttpClient) { 
        super();
        this.headers = this.createAHeader();    
    }

    public count(filtro: Filtro): Promise<any> {
        return this.http.post(`${environment.apiUrl}rota/count`, filtro, { headers: this.headers }).toPromise();
    }

    public listar(filtro: Filtro): Promise<any> {
        return this.http.post(`${environment.apiUrl}rota/list`, filtro, { headers: this.headers }).toPromise();
    }
    
    public deletar(id): Promise<any> {
        return this.http.delete(`${environment.apiUrl}rota/${id}`, { headers: this.headers }).toPromise();
    }

    public editar(rota): Promise<any> {
        return this.http.put(`${environment.apiUrl}rota/edit`, rota, { headers: this.headers }).toPromise();
    }

    public agendarRota(idMotorista, dataIntinerario, cupons): Promise<any> {
        return this.http.post(`${environment.apiUrl}rota/criar`, { idMotorista: idMotorista, dataIntinerario: dataIntinerario, cupons: cupons }, { headers: this.headers }).toPromise();
    }

    public encontrarRotaPeloIdIntinerario(pacote: any): Promise<any> {
        return this.http.post(`${environment.apiUrl}rota/intinerario`, pacote, { headers: this.headers }).toPromise();
    }
    
    public criar(pacote: any): Promise<any> {
        return this.http.post(`${environment.apiUrl}rota/primeira`, pacote, { headers: this.headers }).toPromise();
    }
    
    public encontrarPeloId(pacote: any): Promise<any> {
        return this.http.post(`${environment.apiUrl}rota`, pacote, { headers: this.headers }).toPromise();
    }
    
    public rotaProduto(id: any): Promise<any> {
        return this.http.get(`${environment.apiUrl}rota/produtos/${id}`, { headers: this.headers }).toPromise();
    }
}