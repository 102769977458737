import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Filtro } from '../model/filtro.model';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class ProdutoService extends BaseService{
 
    private headers: HttpHeaders;

    constructor(private http: HttpClient) { 
        super();
        this.headers = this.createAHeader();    
    }
    
    public count(filtro: Filtro): Promise<any> {
        return this.http.post(`${environment.apiUrl}produto/count`,filtro, { headers: this.headers }).toPromise();
    }

    public encontrarPeloId(id: any): Promise<any> {
        return this.http.get(`${environment.apiUrl}produto/${id}`, { headers: this.headers }).toPromise();
    }

    public listar(filtro: Filtro): Promise<any> {
        return this.http.post(`${environment.apiUrl}produto/list`, filtro, { headers: this.headers }).toPromise();
    }
    
    public deletar(id): Promise<any> {
        return this.http.delete(`${environment.apiUrl}produto/${id}`, { headers: this.headers }).toPromise();
    }

    public editar(produto): Promise<any> {
        return this.http.put(`${environment.apiUrl}produto`, produto, { headers: this.headers }).toPromise();
    }

    public criar(produto): Promise<any> {
        return this.http.post(`${environment.apiUrl}produto`, produto, { headers: this.headers }).toPromise();
    }
    
    public listarProdutosDaCargaPeloMotorista(id): Promise<any> {
        return this.http.get(`${environment.apiUrl}produto/motorista/${id}`, { headers: this.headers }).toPromise();
    }
    
    public countProdutosDaCargaPeloMotorista(id): Promise<any> {
        return this.http.get(`${environment.apiUrl}produto/motorista/${id}`, { headers: this.headers }).toPromise();
    }
}