import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TipoOperacaoEnum } from 'src/app/enum/tipoOperacao';
import { TelaEnum } from 'src/app/enum/tipoTela';
import { CardCupom } from 'src/app/model/card-cupom.model';
import { Cliente } from 'src/app/model/cliente.model';
import { Cupom } from 'src/app/model/cupom.model';
import { Filtro } from 'src/app/model/filtro.model';
import { CupomService } from 'src/app/service/cupom.service';
import { EnderecoService } from 'src/app/service/endereco.service';
import { RotaService } from 'src/app/service/rota.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import * as moment from 'moment';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-cupom-list',
  templateUrl: './cupom-list.component.html',
  styleUrls: ['./cupom-list.component.css']
})
export class CupomListComponent implements OnInit {

  @Output() filtro: Filtro = new Filtro();
  @Output() motoristas: Cliente[] = [];
  tela: TelaEnum = TelaEnum.cupom; 
  cupons: any[] = [];
  showOverlay: boolean = false;
  count: number = 0;
  selecaoAgenda: any[] = [];
  agendar = false;
  cidades = [];
  currentUser;

  constructor(
    private cupomService: CupomService,
    private usuarioService: UsuarioService, 
    private enderecoService:EnderecoService,
    private router: Router,
    private rotaService: RotaService, 
    private authService: AuthService) {
      this.authService.currentUser.subscribe(x => this.currentUser = x);  
    }
    
  async ngOnInit() {
    this.start();
  } 

  async start(){
    this.showOverlay = true;
    if(this.currentUser.role != 1){
      this.filtro.dados.created_by = this.currentUser.id;
    }
    await this.construirFiltro();
    this.reload();
  }
  
  async reload(){
    let cupom = await this.converterResponseCupomList(await this.cupomService.listar(this.filtro));
    this.cupons = await this.montarAgrupamentoDosCards(cupom);
    await this.montarListaDeMotorista();
    await this.listarCidadesFiltro();
    this.showOverlay = false;
  }

  async listarCidadesFiltro(){
    let cidades = await this.enderecoService.listarCidades();
    this.cidades = cidades.value;
  }
 
  async montarListaDeMotorista(){
    let motoristas = await this.usuarioService.listarMotorista();
    this.motoristas = motoristas.value;
  }

  async montarAgrupamentoDosCards(cupons){
    let grupos = [];
    if(cupons != undefined){
      if(cupons.length){
        if(cupons.length <=this.filtro.quantidadePorPargina){
          grupos.push(cupons);
          await this.montarListaDeSelecaoAgendamento(cupons);
        }
        else{
          for(let i = 1; i <= cupons.length; i++){
            if(i%this.filtro.quantidadePorPargina == 0){
              grupos.push(cupons.slice(i-this.filtro.quantidadePorPargina, i));
              await this.montarListaDeSelecaoAgendamento(cupons.slice(i-this.filtro.quantidadePorPargina, i));
              if(cupons.length - i <= this.filtro.quantidadePorPargina){
                grupos.push(cupons.slice(i, cupons.length));
                await this.montarListaDeSelecaoAgendamento(cupons.slice(i, cupons.length));
                break;
              }
            }
          }
        }
      }
    }
    return grupos;
  }

  montarListaDeSelecaoAgendamento(cupons){
    if(cupons!=undefined){
      if(cupons.length){
        let lista = cupons.filter(x=> x.flag_pago == 0  && x.valor > 0);
        if(lista != undefined){
          let ids = this.selecaoAgenda.map(x=> x.id);
          for(let cupom of lista){
            if(!ids.includes(cupom.id)){
              this.selecaoAgenda.push({ id: cupom.id, agendar: false });
            }
          }
        }
      }
    }
  }

  adicionarAgendamento(event){
    let cupom = this.selecaoAgenda.find(x=> x.id == event.id);
    if(cupom != undefined){
      cupom.agendar = event.agendar;
    }
  }

  agendarRemoverTodosOsCuponsDaTela(event){
    for(let cupom of this.selecaoAgenda){
      if(event.id.find( x=> x == cupom.id))
      cupom.agendar = event.status;
    }
  }

  agendarCuponsSelecionados(event){
    let existeAgendamento = this.selecaoAgenda.filter(x=> x.agendar);
    if(existeAgendamento.length)
      this.agendar = event;
  }

  fecharModal(e){
    this.agendar = false;
    if(e.status) {
      this.showOverlay = true;
      this.rotaService
        .agendarRota(e.idMotorista, e.data, this.selecaoAgenda.filter(x=> x.agendar).map(x=> x.id)).then(params=>{
          for (var i = 0; i < this.selecaoAgenda.length; i++) {
            this.selecaoAgenda[i]['agendar'] = false;
          }
          this.reload();
        });
        this.showOverlay = false;
    }
  }

  async construirFiltro(){
    this.filtro.dados.dataDe = moment(new Date).format("yyyy-MM-DD");
    this.filtro.dados.dataAte = moment(new Date).format("yyyy-MM-DD");
    this.filtro.dados.role = this.currentUser.role == 1;
    await this.cupomService.count(this.filtro).then(x =>{
      let count = x[0].count
      this.filtro.paginas = count <= this.filtro.quantidadePorPargina ? 1 : Math.ceil(count / this.filtro.quantidadePorPargina) ;
      this.filtro.totalDeRegistros =  count;
      this.count = count;
    });
  }

  async adicionarCards(filtro){
    this.showOverlay = true;
    let cupons = await this.converterResponseCupomList(await this.cupomService.listar(filtro));
    let grupos = await this.montarAgrupamentoDosCards(cupons);
    for(let cp of grupos[0]){
      this.cupons[0].push(cp);
    }
    this.showOverlay = false;
  }

  async pesquisar(filtro:Filtro = null){
    this.showOverlay = true;
    this.filtro.dados.role = this.currentUser.role == 1;
    await this.cupomService.count(filtro).then( x =>{
      let count = x[0].count
      filtro.paginas = count <= this.filtro.quantidadePorPargina ? 1 : Math.ceil(count / this.filtro.quantidadePorPargina) ;
      filtro.totalDeRegistros = count;
      filtro.paginas = 1;
      filtro.pagina = 1;
      this.count = count;
    });

    let cupons = await this.converterResponseCupomList(await this.cupomService.listar(this.filtro));
    this.cupons = await this.montarAgrupamentoDosCards(cupons);
    this.filtro = filtro;
    this.showOverlay = false;
  }

  async deletar(id){
    this.showOverlay = true;
    await this.cupomService.deletar(id).then(params=>{
      this.router.navigate(['/cupom'],  { queryParams: { message: TipoOperacaoEnum.deletar.valueOf() }} );
      this.reload();
    });
    this.showOverlay = false;
  }

  async cupomPago(id){
    
    this.showOverlay = true;
    let result = await this.cupomService.encontrarPeloId(id);
    let cupom: Cupom = result.value;
    cupom.flag_pago = true;

    await this.cupomService.editar(cupom).then(params =>{
      this.router.navigate(['/cupom'],  { queryParams: { message: TipoOperacaoEnum.baixaCupom.valueOf() }} );
      this.filtro.pagina = 1;
      this.start();
    });
    this.showOverlay = false;
  }

  criarMensagem(produtos, cupom, cliente): string{
    let filtrados = produtos.filter(x=> x['id_cupom'] == cupom['id']);
    let mensagem  = `PEQUENO MINEIRO. %0A %0A OLÁ, ${cliente.nome}, VOCÊ ACABOU DE RECEBER UMA COMPROVAÇÃO DOS PRODUTOS RECEBIDOS E VALORES A SEREM ACERTADOS PARA O DIA ${moment(cupom.data_cobranca).format("DD/MM/yyyy")}. PARA EFETIVAR O RECEBIMENTO, RESPONDA A MENSAGEM COM A PALAVRA: " RECEBIDO ".: %0A %0A`;
    let preco:number = 0;
    let lista = [];

    if(filtrados != undefined){
      if(filtrados.length)
        lista = filtrados;
      else
        lista.push(filtrados);
    }
    else return null;

    for(let produto of lista){

        mensagem += `${ produto.quantidade } - ${ produto.nome_filho != null ? produto.nome_filho : produto.nome_pai } - R$ ${ (produto.quantidade * produto.preco).toFixed(2) } %0A`;
      
      preco += parseFloat((produto.quantidade * produto.preco).toFixed(2));
    }

    mensagem += " %0A %0AContatos para esclarecimentos: %0A %0A (21) 96470-6235 %0A (21) 98176-2067"
    return mensagem;
  }

  criarMensagemRepasse(cupom, cliente){
    return `
    OLÁ, 
    ${ cliente.nome } ,
     GOSTARIAMOS DE CONFIRMAR O VALOR DO REPASSE DE R$ ${ cupom.valor } PARA O DIA ${moment(cupom.data_cobranca).format("DD/MM/yyyy")}. , RESPONDA A MENSAGEM COM A PALAVRA: " RECEBIDO".`;
  }

  converterResponseCupomList(data){
    var listCards:CardCupom[] = [];
    
    for(let cupom of data.cupons){
      var card = new CardCupom();
      var cliente: Cliente =  data.clientes.find(x => x['id'] == cupom['id_cliente'])

      card.id = cupom['id'];
      card.motorista = cupom['motorista'];
      card.data_rota = cupom['data_rota']
      card.id_cliente = cupom['id_cliente'];
      card.data_cobranca = cupom['data_cobranca'];
      card.data_vencimento = cupom['data_vencimento'];
      card.flag_pago = cupom['flag_pago'];
      card.valor = cupom['valor'];
      card.valor_adiantado = cupom['valor_adiantado'];
      card.valor_pago = cupom['valor_pago'];
      card.Produtos = data.produtos.find(x => x['id_cupom'] == cupom['id']);
      card.nome_cliente = cliente.nome;
      card.cpf = cliente.cpf;
      card.numero = cliente.numero;
      card.rua = cliente.rua;
      card.id_cidade = cliente.id_cidade;
      card.id_bairro = cliente.id_bairro;
      card.nome_bairro = cupom['nome_bairro'];
      card.nome_cidade = cupom['nome_cidade'];
      card.whatsapp = cliente.whatsapp;
      card.flag_repasse = cupom['flag_repasse'];

      if(cupom.flag_repasse == true){
        card.mensagem_wpp = this.criarMensagemRepasse(cupom, cliente);
      }else{
        card.mensagem_wpp = this.criarMensagem(data.produtos, cupom, cliente);
      }

      listCards.push(card);
    }

    return listCards;
  }
}
