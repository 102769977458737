import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Cliente } from 'src/app/model/cliente.model';
import { AuthService } from 'src/app/service/auth.service';
import { ClienteService } from 'src/app/service/cliente.service';
import { EnderecoService } from 'src/app/service/endereco.service';

@Component({
  selector: 'app-cliente-form',
  templateUrl: './cliente-form.component.html',
  styleUrls: ['./cliente-form.component.css']
})
export class ClienteFormComponent implements OnInit {

  formulario:FormGroup;
  validations:boolean = false;
  cliente: Cliente = new Cliente();
  id:number;
  showOverlay:boolean = false;
  modal:boolean = false;
  painel:boolean = false;
  cidades = [];
  bairros = [];
  mensagem: string = 'Esse cpf ja se encontra na base de dados, deseja salvar mesmo assim?'
  id_cidade = 0;
  id_bairro = 0;
  administrador: boolean = true;
  currentUser;
  
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private clienteService: ClienteService,
    private enderecoService:EnderecoService,
    private formBuilder: FormBuilder, 
    private authService: AuthService) {
      this.authService.currentUser.subscribe(x => this.currentUser = x);  
      this.administrador = this.currentUser.role == 1;
    }

  async ngOnInit() {
    this.showOverlay = true;
    await this.construirFormulario();
    await this.identificarOperacao();
    await this.montarComboCidade();
    this.showOverlay = false;
  }

  async montarComboCidade(){
    let cidades = await this.enderecoService.listarCidades();
    this.cidades = cidades.value;
  }

  async setCidade(data){
    this.id_cidade= data.target.value;
    await this.montarComboBairros(this.id_cidade);
  }

  async setBairro(data){
    this.id_bairro = data.target.value;
  }

  async montarComboBairros(id){
    let bairros = await this.enderecoService.listarBairroPorCidade(id);
    this.bairros = bairros.value;
  }

  construirFormulario(){
    this.formulario = this.formBuilder.group({
      id:0,
      nome: ['', Validators.required],
      cpf:['', Validators.required],
      telefone:['', Validators.required],
      whatsapp:['', Validators.required],
      rua:['', Validators.required],
      numero:['', Validators.required],
      cep:['', Validators.required],
      complemento:['', Validators.required],
      sub_bairro:['', Validators.required],
      id_bairro:[0, Validators.required],
      id_cidade:[0, Validators.required]
    });
  }

  async alimentarFormulario(){
    this.formulario.patchValue({
      id: this.cliente.id,
      nome: this.cliente.nome,
      cpf:  this.cliente.cpf.replace(/[^0-9]/g, ''),
      telefone: this.cliente.telefone,
      whatsapp: this.cliente.whatsapp,
      rua: this.cliente.rua,
      numero: this.cliente.numero,
      cep: this.cliente.cep,
      complemento: this.cliente.complemento,
      sub_bairro: this.cliente.sub_bairro,
      id_bairro: this.cliente.id_bairro,
      id_cidade: this.cliente.id_cidade,
    });
    this.id_cidade = parseInt(this.cliente.id_cidade);
    await this.montarComboBairros(this.id_cidade);
    this.id_bairro = parseInt(this.cliente.id_bairro);
  }

  validarFormulario(){
    if(this.formulario.valid && this.id_bairro != 0 && this.id_cidade != 0){
      this.validations = false;
    }else{
      this.validations = true;
    }
  }

  async identificarOperacao(){
    this.id = this.route.snapshot.params['id'];
    if(this.id != undefined){
      var usuario = await this.clienteService.encontrarPeloId(this.id);
      this.cliente = usuario.value[0];
      this.alimentarFormulario();
    }
  }

  async validarSeCpfExiste(){
    let cpf = this.formulario.get('cpf').value;
    let cpfIsValid = await this.clienteService.encontrarPeloCPF(cpf.replace(/[^0-9]/g, ''));
    if(cpfIsValid.value.length){
      this.modal = true;
    } else {
      this.salvarCliente();
    }
  }

  async onSubmit(){
    this.cliente = this.formulario.value;
    this.cliente.id_bairro = this.id_bairro.toString();
    this.cliente.id_cidade = this.id_cidade.toString();

    this.validarFormulario();
    if(!this.validations){
      if(this.id == undefined){
        this.modal = true;
        this.painel = true;
      } else {
        await this.salvarCliente();
      }
    }
  }

  async fecharModal(salvar){
    this.modal = false;
    if(salvar && this.painel){      
      this.modal = false;
      this.painel = false;  
      await this.validarSeCpfExiste();
    }else if(salvar){
      await this.salvarCliente();
    }
  }

  async salvarCliente(){
    this.cliente.created_by = this.currentUser.id;
    this.cliente.cpf = this.cliente.cpf.replace(/[^0-9]/g, '');
    this.cliente.telefone = this.cliente.telefone.replace(/[^0-9]/g, '');
    this.cliente.whatsapp = this.cliente.whatsapp.replace(/[^0-9]/g, '');

    this.showOverlay = true;
    if(this.id != undefined){
      await this.clienteService.editar(this.cliente)
      .then(x=>{
        this.showOverlay = false;
        this.router.navigate(['/cliente'],  { queryParams: { message: 2 }} );
      })
      .catch(x=>{
        this.showOverlay = false;
        this.router.navigate(['/cliente'],  { queryParams: { message: 0 }} );
      });
    } 
    else
    {
      await this.clienteService.criar(this.cliente)
      .then(x=>{
        this.showOverlay = false;
        this.router.navigate(['/cliente'],  { queryParams: { message: 1 }} );
      })
      .catch(x=>{
        this.showOverlay = false;
        this.router.navigate(['/cliente'],  { queryParams: { message: 0 }} );
      });
    } 
  }
}
