<div class="container m-auto pt-3 pb-5">
    <div class="my-overlay" *ngIf="showOverlay">
      <div class="spinner-border m-5" role="status" style="color: white;" *ngIf="showOverlay">
        <span class="visually-hidden"></span>`
      </div>
    </div>
    <div>

    <div class="mb-3">
      <label for="" class="form-label">Selecione o motorista</label>
      <select class="browser-default custom-select" (change)="setIdMotorista($event);">
        <option value="0" selected="{{idMotorista == 0 ? true : null}}">Selecione</option>
        <option value="{{motorista.id}}" selected="{{idMotorista == motorista.id ? true : null}}" *ngFor="let motorista of motoristas">{{motorista.nome}}</option>
      </select>
      <!-- <div *ngIf="!validations" class="alert alert-danger mt-2">Motorista obrigatório</div> -->
    </div>
    
    <div class="mb-3">
      <label for="" class="form-label">Selecione a data de cobranca</label>
      <input type="date" class="form-control" id="dataCobranca" aria-describedby="dataCobranca" (change)="setDataIntenerario($event);" value="{{dataRota}}">
      <!-- <div  *ngIf="!validations" class="alert alert-danger mt-2">Data de cobranca obrigatório</div> -->
    </div>

    <div class="row mb-3">
      <div class="col-sm-12 col-md-6 col-lg-4 p-0 mt-2 pl-1 pr-1" *ngFor="let item of produtos">
        <div class="card">
          <div class="card-body">
            <input *ngIf="item.nome_filho == null" type="text" class="form-control mb-4" value="{{item.nome_pai}}" readonly/>
            <select *ngIf="item.nome_filho != null" class="browser-default custom-select mb-4" (change)="lotearVenda(item.id, item.lote);">
              <option value="1">{{item.nome_filho}}</option>
              <option value="{{ item.lote }}">{{item.nome_pai}}</option>
            </select>
            <h4>Adicionados <strong>{{ item.quantidade }}</strong></h4>
            <a class="card-link btn btn-danger" (click)="removerProduto(item.id);">Remover</a>
            <a class="card-link btn btn-danger" (click)="adiconarProduto(item.id);">Adicionar</a>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-4 mt-4 text-center">
        <div class="alert alert-light" role="alert">
          R$ {{ valorDaMercadoria | number:'2.1-2' }} em produtos na rota
        </div>
    </div>

    <div class="mb-3">
        <label for="" class="form-label">Valor do abastecimento</label>
        <input type="number" class="form-control" aria-describedby="abastecimento" (keyup)="setValorCombustivel($event)" value="{{ combustivel }}">
    </div>
    
    <div class="mb-3">
        <label for="" class="form-label">Valor do refeição</label>
        <input type="number" class="form-control" aria-describedby="refeicao" (keyup)="setValorRefeicao($event)" value="{{ refeicao }}">
    </div>

    <div class="d-flex mt-3 justify-content-center">
      <a routerLink="/rota" class="btn btn-warning">Cancelar</a>
    <button type="button" class="btn btn-danger ml-2" (click)="salvar();">Salvar</button>
    </div>
</div>
</div>