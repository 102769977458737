import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Cliente } from 'src/app/model/cliente.model';
import { Filtro } from 'src/app/model/filtro.model';
import { RotaService } from 'src/app/service/rota.service';
import { UsuarioService } from 'src/app/service/usuario.service';

@Component({
  selector: 'app-intinerario-agendamento',
  templateUrl: './intinerario-agendamento.component.html',
  styleUrls: ['./intinerario-agendamento.component.css']
})
export class IntinerarioAgendamentoComponent implements OnInit {
  @Output() filtro: Filtro = new Filtro();
  @Input() exibir:boolean = false;
  @Input() intinerario = null;
  @Output() saida: EventEmitter<any> = new EventEmitter();
  showOverlay:boolean = false;
  idMotorista: Number = 0;
  dataIntinerario:string = '';
  motoristas:Cliente[] = [];
  idCupom: Number = 0;
  validations:boolean = true;

  constructor(
    private usuarioService: UsuarioService, 
    private route: ActivatedRoute) { }

  async ngOnInit() {
    this.start();
  }

  async setIdCupom(){
    this.idCupom = this.route.snapshot.params['id'];
  }

  async start(){
    this.showOverlay = true;
    await this.setIdCupom();
    await this.montarListaDeMotorista();
    this.showOverlay = false;
  }

  async montarListaDeMotorista(){
    let motoristas = await this.usuarioService.listarMotorista();
    this.motoristas = motoristas.value;
    this.showOverlay = false;
  }

  setDataIntenerario(data){
    this.dataIntinerario = data.target.value;
  } 

  setIdMotorista(data){
    this.idMotorista = data.target.value;
  } 

  validarFormulario(){
      this.validations = this.idMotorista != 0 && this.dataIntinerario.length != 0;
  }

  async retorno(event){
    if(event){
      await this.validarFormulario();
      if(this.validations)
        this.saida.emit({status: event, idMotorista: this.idMotorista, data: this.dataIntinerario})
    } 
    else 
    {
      this.saida.emit({status: event})
    }
  }
}
