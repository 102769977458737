<div class="container m-auto pt-3 pb-5">
    <div class="my-overlay" *ngIf="showOverlay">
      <div class="spinner-border m-5" role="status" style="color: white;" *ngIf="showOverlay">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <form [formGroup]="formulario" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="mb-3">
        <label for="NomeUsuario" class="form-label">Nome</label>
        <input type="text" maxlength="100" class="form-control" formControlName="nome_pai" id="nomeProduto" aria-describedby="nomeProduto">
        <div *ngIf="formulario.get('nome_pai').errors?.required && !validations" class="alert alert-danger mt-2">Nome obrigatório</div>
      </div>
      <div class="mb-3">
        <label for="precoProduto" class="form-label">Valor <strong>Unitário</strong></label>
        <input type="string" class="form-control" formControlName="preco" id="precoProduto" aria-describedby="precoProdutoHelp">
        <div *ngIf="formulario.get('preco').errors?.required && !validations" class="alert alert-danger mt-2">Valor obrigatório</div>
      </div>

      <div class="form-check mb-3" *ngIf="id == 0">
        <input class="form-check-input" type="checkbox" (click)="construirFormularioPacote($event)" id="CadastarComoPacote">
        <label class="form-check-label" for="CadastarComoPacote">
          Cadastrar como Caixa
        </label>
      </div>

      <div class="form-check mb-3">
        <input class="form-check-input"formControlName="flag_devolucao"  type="checkbox" id="devolucaoPermitida">
        <label class="form-check-label" for="devolucaoPermitida">
          Devolução
        </label>
      </div>
      
      <div class="mb-3" *ngIf="showPackForm">
        <label for="loteProduto" class="form-label">Quantidade no caixa</label>
        <input type="number" class="form-control" formControlName="lote" id="loteProduto" aria-describedby="loteProdutoHelp">
        <div *ngIf="formulario.get('lote').errors?.required && !validations" class="alert alert-danger mt-2">Valor obrigatório</div>
      </div>
      
      <div class="mb-3" *ngIf="showPackForm">
        <label for="nomeUnidade" class="form-label">Nome da unidade</label>
        <input type="text" class="form-control" formControlName="nome_filho" id="nomeUnidadeProduto" aria-describedby="nomeUnidadeProdutoHelp">
        <div *ngIf="formulario.get('nome_filho').errors?.required && !validations" class="alert alert-danger mt-2">Valor obrigatório</div>
      </div>

      <div class="d-flex mt-3 justify-content-center">
        <a routerLink="/produto" class="btn btn-warning">Cancelar</a>
        <button type="submit" class="btn btn-danger ml-2">Salvar</button>
      </div>
    </form>
  </div>