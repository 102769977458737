<div class="my-overlay" *ngIf="showOverlay">
    <div class="spinner-border m-5" role="status" style="color: white;" *ngIf="showOverlay">
      <span class="visually-hidden"></span>
    </div>
</div>
<app-list  
  [data]="intinerarios" 
  [filtro]="filtro" 
  [motoristas]="motoristas" 
  [cidades]="cidades" 
  [tipoEntradaDeDado]="tela"
  (pesquisar)="pesquisar($event);" 
  (deletar)="deletar($event);"
  (adicionarAgendamento)="adicionarAgendamento($event);" 
  (agendarRemoverTodos)="agendarRemoverTodosOsIntinerariosDaTela($event);"
  (agendarCupons)="agendarIntinerariosSelecionados($event);"
  (adicionarCards)="adicionarCards($event)"
  ></app-list>
  <app-intinerario-agendamento *ngIf="agendar" [motoristas]="motoristas" [cupons]="selecaoAgenda" [exibir]="agendar" (saida)="fecharModal($event);"></app-intinerario-agendamento>