import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Filtro } from '../model/filtro.model';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class EnderecoService extends BaseService{

    private headers: HttpHeaders;

    constructor(private http: HttpClient) { 
        super();
        this.headers = this.createAHeader();    
    }

    public listar(filtro: Filtro): Promise<any> {
        throw new Error('Method not implemented.');
    }
    public criar(usuario: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    public deletar(id: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    public editar(usuario: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    public encontrarPeloId(id: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    public count(filtro: Filtro): Promise<any> {
        throw new Error('Method not implemented.');
    }

    public listarCidades(): Promise<any>{
        return this.http.get(`${environment.apiUrl}enderecos/cidades`, { headers: this.headers }).toPromise();
    }

    
    public listarBairroPorCidade(id): Promise<any>{
        return this.http.get(`${environment.apiUrl}enderecos/bairros/${id}`, { headers: this.headers }).toPromise();
    }

}