<div class="my-overlay" *ngIf="showOverlay">
  <div class="spinner-border m-5" role="status" style="color: white;" *ngIf="showOverlay">
    <span class="visually-hidden"></span>
  </div>
</div>
<div class="container m-auto pt-3 pb-5" *ngIf="bloqueio;else other_content">
    <div class="container">
      <div class="row mb-3">
        <div class="col-sm-12 col-md-12 col-lg-12 p-0 mt-2 pl-1 pr-1">
            <label for="" class="form-label">Busque cliente</label>
            <input class="form-control" list="datalistOptions"  value="{{nomeCliente}}" id="DataList" (change)="setCliente($event);" autocomplete="off" placeholder="Selecione ou busque um cliente" (keyup)="pesquisarCliente($event.target.value);" [disabled]="id > 0">
            <datalist id="datalistOptions">
              <option *ngFor="let item of cliente" value="{{item.nome}}">
            </datalist>
            <div *ngIf="cupom.id_cliente == 0 && !validations" class="alert alert-danger mt-2">Cliente obrigatório</div>
        </div>
        <div  *ngIf="!cupom.flag_repasse" class="col-sm-12 col-md-12 col-lg-12 p-0 mt-2 pl-1 pr-1">
          <label  class="form-label">Valor pago:</label>
          <input type="number" autocomplete="off" (change)="setValorAdiantado($event);" class="form-control" aria-describedby="precoProdutoHelp" value="{{cupom.valor_adiantado}}">
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 p-0 mt-2 pl-1 pr-1">
            <label for="dataCobranca" class="form-label">Data de Cobrança</label>
            <input type="date" class="form-control" id="dataCobranca" (change)="setDataCobranca($event);" aria-describedby="dataCobranca" value="{{cupom.data_cobranca}}">
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 p-0 mt-2 pl-1 pr-1" *ngIf="exibirCupomAberto && administrador">
          <div class="form-check form-check-inline mt-3">
            <input class="form-check-input" id="sync" type="checkbox" (change)="sincronizarEventos($event);">
            <label class="form-check-label" for="sync">Sincronizar as datas de cobrancas dos outros cupons com este</label>
          </div>
        </div>
      </div>
      <div *ngIf="!cupom.flag_repasse" class="row">
        <div class="col-12 p-0 pl-1 pr-1">
          <h3>Selecione os produtos</h3>
        </div>
      </div>
      <div *ngIf="!cupom.flag_repasse" class="row mb-3">
        <div class="col-sm-12 col-md-6 col-lg-4 p-0 mt-2 pl-1 pr-1" *ngFor="let item of produtosComprados">
          <div class="card">
            <div class="card-body">
              <input *ngIf="item.nome_filho == null" type="text" class="form-control mb-4" value="{{item.nome_pai}}" readonly/>
              <select *ngIf="item.nome_filho != null" class="browser-default custom-select mb-4" (change)="lotearVenda(item.id, item.lote);">
                <option value="1">{{item.nome_filho}}</option>
                <option value="{{ item.lote }}">{{item.nome_pai}}</option>
              </select>
              <h4>Adicionados <strong>{{ item.quantidade }}</strong></h4>
              <a class="card-link btn btn-danger" (click)="removerProduto(item.id);"  *ngIf="cupom.flag_pago != 1">Remover</a>
              <a class="card-link btn btn-danger" (click)="adiconarProduto(item.id);"  *ngIf="cupom.flag_pago != 1">Adicionar</a>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 p-0 mt-2 pl-1 pr-1" *ngIf="exibirIncorporacao">
          <div class="card bg-light">
            <div class="card-body">
              <h4> {{  dadosIncorporacao.valor < 0 ? 'Valor de debito' : 'Valor de credito' }} R$ {{ dadosIncorporacao.valor | number:'2.2-2' }}</h4>
              <h4>
                <span *ngIf="this.dadosIncorporacao.valor_utilizado != 0" class="badge bg-success text-white">VALOR APLICADO</span>
                <span *ngIf="this.dadosIncorporacao.valor_utilizado == 0" class="badge bg-danger text-white">NÃO APLICADO</span>
              </h4>
              <button class="card-link btn btn-danger" (click)="removerIncorporacao();" *ngIf="cupom.flag_pago != 1">Remover</button>
              <button class="card-link btn btn-danger" (click)="incorporarOValor();" *ngIf="cupom.flag_pago != 1">Utilizar</button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!cupom.flag_repasse" class="alert alert-light text-dark text-center" role="alert">
        
        <h3>
          Valor da compra R$ {{ valorDaCompra | number:'2.2-2' }}
        </h3>
        <h3>
          Valor pago R$ {{ cupom.valor_adiantado | number:'2.2-2' }}
        </h3>
        <h3 *ngIf="exibirIncorporacao">
          Valor aplicado R$ {{ valorIncorporacao | number:'2.2-2' }}
        </h3>
        <h3>
          Residuo R$ {{ ((valorIncorporacao + quantidadePaga) - valorDaCompra) * -(1.0) | number:'2.2-2' }}
        </h3>
      </div>
      
      <div *ngIf="cupom.flag_repasse" class="alert alert-light text-dark text-center" role="alert">
        <h3>
          Valor do repasse R$ {{ valorDaCompra | number:'2.2-2' }}
        </h3>
      </div>

      <div class="d-flex mt-3 justify-content-center">
        <a routerLink="/cupom" class="btn btn-warning">Cancelar</a>
        <button type="button" class="btn btn-danger ml-2" (click)="validarESalvar();">Salvar</button>
      </div>
  </div>
</div>
  <ng-template #other_content>
    <div class="container m-auto pt-3 pb-5">
      <div class="card">
        <div class="card-header">
          Ops...
        </div>
        <div class="card-body" *ngIf="administrador">
          Cadastre produtos para usar a tela de cupom.
        </div>
        <div class="card-body" *ngIf="!administrador">
          Infelizmente a falta de produtos.
        </div>
      </div>
      <div class="d-flex mt-3 justify-content-center">
        <a routerLink="/cupom" class="btn btn-danger">Voltar</a>
      </div>
    </div>
  </ng-template>
  <app-cupom-valor-residual *ngIf="exibir" [dadosIncorporacao]="dadosIncorporacao" [exibir]="exibir" (saida)="incorporarOValor($event);"></app-cupom-valor-residual>