import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Filtro } from '../model/filtro.model';
import { Usuario } from '../model/usuario.model';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService extends BaseService {

    private headers: HttpHeaders;

    constructor(private http: HttpClient) { 
        super();
        this.headers = this.createAHeader();    
    }
    
    public count(filtro: Filtro): Promise<any> {
        return this.http.post(`${environment.apiUrl}usuario/count`, filtro, { headers: this.headers }).toPromise();
    }

    public listar(filtro: Filtro): Promise<any> {
        return this.http.post(`${environment.apiUrl}usuario/list`, filtro, { headers: this.headers }).toPromise();
    }
    
    public listarMotorista(): Promise<any> {
        return this.http.get(`${environment.apiUrl}usuario/motoristas`, { headers: this.headers }).toPromise();
    }

    public deletar(id): Promise<any> {
        return this.http.delete(`${environment.apiUrl}usuario/${id}`, { headers: this.headers }).toPromise();
    }

    public editar(usuario): Promise<any> {
        return this.http.put(`${environment.apiUrl}usuario`, usuario, { headers: this.headers }).toPromise();
    }

    public criar(usuario: Usuario): Promise<any> {
        return this.http.post(`${environment.apiUrl}usuario`, usuario, { headers: this.headers }).toPromise();
    }

    public encontrarPeloId(id: number): Promise<any> {
        return this.http.get(`${environment.apiUrl}usuario/${id}`, { headers: this.headers }).toPromise();
    }
}