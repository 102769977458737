import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TipoOperacaoEnum } from 'src/app/enum/tipoOperacao';
import { TelaEnum } from 'src/app/enum/tipoTela';
import { Filtro } from 'src/app/model/filtro.model';
import { ClienteService } from 'src/app/service/cliente.service';
import { EnderecoService } from 'src/app/service/endereco.service';

@Component({
  selector: 'app-cliente-list',
  templateUrl: './cliente-list.component.html',
  styleUrls: ['./cliente-list.component.css']
})
export class ClienteListComponent implements OnInit {

  tela: TelaEnum = TelaEnum.cliente;
  filtro: Filtro = new Filtro();
  clientes:any[] = [];
  showOverlay:boolean = false;
  palavraChave = '';
  esconderBotaoPaginacao = false;
  count: number = 0;
  cidades = [];

  constructor(
    private clienteService: ClienteService,
    private enderecoService:EnderecoService,
    private router: Router) { }

  async ngOnInit() {    
    this.start();
  }

  async start(){
    this.showOverlay = true;
    await this.construirFiltro();
    await this.listarCidadesFiltro();
    var cliente = await this.clienteService.listar(this.filtro);
    this.clientes = await this.montarAgrupamentoDosCards(cliente.value);
    this.showOverlay = false;
  }

  
  async montarAgrupamentoDosCards(clientes){
    let grupos = [];
    if(clientes != undefined){
      if(clientes.length){
        if(clientes.length <=this.filtro.quantidadePorPargina){
          grupos.push(clientes);
        }
        else{
          for(let i = 1; i <= clientes.length; i++){
            if(i%this.filtro.quantidadePorPargina == 0){
              grupos.push(clientes.slice(i-this.filtro.quantidadePorPargina, i));
              if(clientes.length - i <= this.filtro.quantidadePorPargina){
                grupos.push(clientes.slice(i, clientes.length));
                break;
              }
            }
          }
        }
      }
    }
    return grupos;
  }

  async listarCidadesFiltro(){
    let cidades = await this.enderecoService.listarCidades();
    this.cidades = cidades.value;
  }

  async construirFiltro(){
    let res = await this.clienteService.count(this.filtro)
    let count = res.value[0].count;
    
    this.filtro.paginas = count <= this.filtro.quantidadePorPargina ? 1 : Math.ceil(count / this.filtro.quantidadePorPargina) ;
    this.filtro.totalDeRegistros =  count;
    this.count = count;
  }

  async adicionarCards(filtro){
    this.showOverlay = true;
    let clientes = await this.clienteService.listar(filtro);
    let grupos = await this.montarAgrupamentoDosCards(clientes.value);
    this.clientes.push(grupos[0])
    this.showOverlay = false;
  }

  async pesquisar(filtro:Filtro = null){
    this.showOverlay = true;
    await this.clienteService.count(filtro).then( x =>{
      let count = x.value[0].count;
      filtro.paginas = count <= this.filtro.quantidadePorPargina ? 1 : Math.ceil(count / this.filtro.quantidadePorPargina) ;
      filtro.totalDeRegistros =  count;
      filtro.paginas = 1;
      filtro.pagina = 1;
      this.count = count;
    });
    await this.clienteService.listar(filtro).then(async x => this.clientes = await this.montarAgrupamentoDosCards(x.value));
    this.filtro = filtro;
    this.showOverlay = false;
  }

  async deletar(id){
    this.showOverlay = true;
    await this.clienteService.deletar(id).then(params=>{
      this.router.navigate(['/cliente'],  { queryParams: { message: TipoOperacaoEnum.deletar.valueOf() }} );
      this.filtro.pagina = 1;
      this.start();
    });
    this.showOverlay = false;
  }
}
