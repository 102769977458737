<div class="modal d-block mt-5" tabindex="-1" >
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">Escolha o motorista e a nova data de cobrança </span>
      </div>
      <div class="modal-body">
          <div class="mb-3">
              <label for="" class="form-label">Selecione o motorista</label>
              <select class="browser-default custom-select" (change)="setIdMotorista($event);">
                  <option value="0">Selecione</option>
                  <option value="{{motorista.id}}" *ngFor="let motorista of motoristas">{{motorista.nome}}</option>
              </select>
              <div *ngIf="!validations" class="alert alert-danger mt-2">Motorista obrigatório</div>
          </div>
            
          <div class="mb-3">
              <label for="" class="form-label">Selecione a nova data de cobranca</label>
              <input type="date" class="form-control" id="dataCobranca" aria-describedby="dataCobranca" (change)="setDataIntenerario($event);">
              <div  *ngIf="!validations" class="alert alert-danger mt-2">Data de cobranca obrigatório</div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="retorno(false);">Cancelar</button>
        <button type="button" class="btn btn-danger" (click)="retorno(true);">Reagendar</button>
      </div>
    </div>
  </div>
</div>
<div class="my-overlay" *ngIf="exibir" style="z-index: 99 !important;"></div>