import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Filtro } from '../model/filtro.model';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class ClienteService extends BaseService{

    private headers: HttpHeaders;

    constructor(private http: HttpClient) { 
        super();
        this.headers = this.createAHeader();    
    }

    public listar(filtro:Filtro): Promise<any> {
        return this.http.post(`${environment.apiUrl}cliente/list`, filtro, { headers: this.headers }).toPromise();
    }

    public count(filtro: Filtro): Promise<any> {
        return this.http.post(`${environment.apiUrl}cliente/count`, filtro, { headers: this.headers }).toPromise();
    }

    public encontrarPeloId(id: any): Promise<any> {
        return this.http.get(`${environment.apiUrl}cliente/${id}`, { headers: this.headers }).toPromise();
    }

    public encontrarPeloCPF(cpf: any): Promise<any> {
        return this.http.get(`${environment.apiUrl}cliente/cpf/${cpf}`, { headers: this.headers }).toPromise();
    }
    
    public deletar(id): Promise<any> {
        return this.http.delete(`${environment.apiUrl}cliente/${id}`, { headers: this.headers }).toPromise();
    }

    public editar(cliente): Promise<any> {
        return this.http.put(`${environment.apiUrl}cliente`, cliente, { headers: this.headers }).toPromise();
    }

    public criar(cliente): Promise<any> {
        return this.http.post(`${environment.apiUrl}cliente`, cliente, { headers: this.headers }).toPromise();
    }

    public situacao(data): Promise<any> {
        return this.http.post(`${environment.apiUrl}cliente/situacao`, data, { headers: this.headers }).toPromise();
    }

}