import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  currentUser: any;
  constructor(
    private router: Router,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    if(this.currentUser == undefined && this.currentUser == null)
      this.authService.currentUser.subscribe(x => this.currentUser = x);
  }

  logout(){
    this.authService.logout();
    window.location.reload()
  }

}
