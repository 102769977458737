import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Compra } from 'src/app/model/compra.model';
import { Filtro } from 'src/app/model/filtro.model';
import { Produto } from 'src/app/model/produto.model';
import { ProdutoService } from 'src/app/service/produto.service';
import { RotaService } from 'src/app/service/rota.service';
import * as moment from 'moment';
import { UsuarioService } from 'src/app/service/usuario.service';
import { Cliente } from 'src/app/model/cliente.model';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-rota-form',
  templateUrl: './rota-form.component.html',
  styleUrls: ['./rota-form.component.css']
})
export class RotaFormComponent implements OnInit {

  showOverlay:boolean = false;
  produtos: Compra[] = [];
  countProduto: number;
  valorDaMercadoria:number = 0;
  combustivel:number;
  refeicao:number;
  filtro: Filtro = new Filtro();
  id:number = 0;
  dataRota:string = '';
  motoristaDaRota:string = '';
  rota:any = [];
  idMotorista: Number = 0;
  motoristas:Cliente[] = [];
  validations:boolean = true;
  currentUser;

  constructor(
    private usuarioService: UsuarioService, 
    private produtoService: ProdutoService,
    private route: ActivatedRoute, 
    private rotaService:RotaService,
    private router: Router,
    private authService: AuthService
  ) { 
    this.authService.currentUser.subscribe(x => this.currentUser = x);  
  }

  async ngOnInit() {
    this.showOverlay = true;
    await this.construirFiltroDeConsulta();
    await this.montarDataListDeVendaProduto();
    await this.montarListaDeMotorista();
    await this.identificarOperacao();
    this.rota.created_by = this.currentUser.id;
    this.showOverlay = false;
  }

  lotearVenda(id, lote){
    let produtos;
    
    if(this.produtos.length > 1)
      produtos = this.produtos.find(x=> x.id == id);
    else
      produtos = this.produtos[0];

    produtos.lotear = lote != 1 && !produtos.lotear;
  }

  async identificarOperacao(){
    this.id = this.route.snapshot.params['id'];

    if(this.id != undefined){
      this.dataRota = moment(this.route.snapshot.params['data']).format("yyyy-MM-DD");
      this.idMotorista = this.route.snapshot.params['motorista'];
      this.carregaRota();
    }
  }

  async montarListaDeMotorista(){
    let motoristas = await this.usuarioService.listarMotorista();
    this.motoristas = motoristas.value;
    this.showOverlay = false;
  }

  async carregaRota(){
    this.rota = await this.rotaService.encontrarPeloId({id: this.id, data: this.dataRota, motorista: this.motoristaDaRota});
    this.montarFomularioRota();
    this.montarProdutos();
  }

  montarFomularioRota(){
    this.combustivel = this.rota.rota.valor_abastecimento;
    this.refeicao = this.rota.rota.valor_almoco;
  }

  montarProdutos(){
    if(this.rota.produtos.length){
      for(let produto of this.rota.produtos[0])
        for(let i = 0; i < produto.entrada; i++){
          this.adiconarProduto(produto.id)
        }
    }
  }

  construirFiltroDeConsulta(){
    this.filtro.quantidadePorPargina = 1000;
    this.filtro.pagina = 1
  }

  async montarDataListDeVendaProduto(palavraChave = ''){
    this.filtro.paginas = this.countProduto / 2;
    this.filtro.dados.palavraChave = palavraChave;
   
    var produtos = await this.produtoService.listar(this.filtro);
    this.construirInputsProdutos(produtos.value);
  }

  setDataIntenerario(data){
    this.dataRota = this.dataRota = moment(data.target.value).format("yyyy-MM-DD");
  } 

  setIdMotorista(data){
    this.idMotorista = data.target.value;
  } 

  adiconarProduto(id){
    let produto = this.produtos.find(x=> x.id == id);
    
    if(produto == undefined) produto = new Compra();

    if(produto.lotear){
      produto.quantidade += (1 * produto.lote);
      produto.precoTotal += (produto.preco * produto.lote)
    } else {
      produto.quantidade += 1;
      produto.precoTotal += produto.preco
    }

    this.calcularPreco();
  }

  removerProduto(id){
    let produto = this.produtos.find(x=> x.id == id);

    if(produto.quantidade > 0){
      if(produto.lotear) {

        if((produto.quantidade - (1 * produto.lote)) > 0){
          produto.quantidade -= (1 * produto.lote);
          produto.precoTotal -= (produto.preco * produto.lote);
        }else{
          produto.quantidade = 0;
          produto.precoTotal = 0;
        }

      } else {
        produto.quantidade -= 1;
        produto.precoTotal -= produto.preco;
      }
      this.calcularPreco();
    }
  }
  
  calcularPreco(){
    let precoFinal = this.produtos.reduce((precoTotal, item) => precoTotal + item.precoTotal, 0);
    let numero = Number.parseFloat((Math.round(precoFinal * 100) / 100).toFixed(2));
    this.valorDaMercadoria = numero;
  }

  construirInputsProdutos(produto:Produto[]){
    produto.forEach(x=>{
      let compra = new Compra();
      compra.id = x.id;
      compra.preco = x.preco;
      compra.nome_pai = x.nome_pai;
      compra.nome_filho = x.nome_filho;
      compra.lote = x.lote;
      this.produtos.push(compra);
    });
  }

  setValorCombustivel(event){
    if(event.target.value.length)
      this.combustivel = event.target.value;
  }

  setValorRefeicao(event){
    if(event.target.value.length)
      this.refeicao = event.target.value;
  }

  validarFormulario(){
    this.validations = this.idMotorista != 0 && this.dataRota.length != 0;
  }

  salvar(){
    this.validarFormulario();

    if(this.validations){

      let request = {
        id: this.id,
        refeicao: this.refeicao,
        combustivel: this.combustivel,
        valor: this.valorDaMercadoria,
        data: this.dataRota,
        idMotorista: this.idMotorista,
        produtos: this.produtos.filter(x=> x.quantidade != 0)
      };

      if(this.id == undefined) {
        this.rotaService.criar(request).then(x => {
          this.showOverlay = false;
          this.router.navigate(['/rota'],  { queryParams: { message: 2 }} );
        }).catch(x => {
          this.showOverlay = false;
          this.router.navigate(['/rota'],  { queryParams: { message: 0 }} );
        })
      } else {
        this.rotaService.editar(request).then(x => {
          this.showOverlay = false;
          this.router.navigate(['/rota'],  { queryParams: { message: 2 }} );
        }).catch(x => {
          this.showOverlay = false;
          this.router.navigate(['/rota'],  { queryParams: { message: 0 }} );
        })
      }
    }
  }

  
}
