import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Compra } from 'src/app/model/compra.model';
import { Cupom } from 'src/app/model/cupom.model';
import { Filtro } from 'src/app/model/filtro.model';
import { AuthService } from 'src/app/service/auth.service';
import { IntinerarioService } from 'src/app/service/intinerario.service';

@Component({
  selector: 'app-intinerario-form',
  templateUrl: './intinerario-form.component.html',
  styleUrls: ['./intinerario-form.component.css']
})
export class IntinerarioFormComponent implements OnInit {

  showOverlay:boolean = false;
  filtro: Filtro = new Filtro();
  countProduto: number;
  cupom: Cupom = new Cupom();
  intinerario: any;
  produtosDeDevolucao: any[] =[];
  id:number;
  valorDaCompra:number = 0;
  valorDaDevolucao:number = 0;
  valorFinal:number = 0;
  valorPagoPeloCliente:number = 0;
  currentUser;
  validations:boolean = true;
  exibir:boolean = false;
  mediaPreco:number =0;
  lookSave: boolean = false;

  constructor(
    private intinerarioService: IntinerarioService,
    private router: Router, 
    private route: ActivatedRoute, 
    private authService: AuthService) {
      this.authService.currentUser.subscribe(x => this.currentUser = x);  
    }

  async ngOnInit() {
    this.showOverlay = true;
    await this.carregarIntinerario();
    await this.construirFiltroDeConsulta();
    this.intinerario.created_by = this.currentUser.id;
    this.showOverlay = false;
  }

  lotearVenda(id, lote){
    let produtos;
    
    if(this.produtosDeDevolucao.length > 1)
      produtos = this.produtosDeDevolucao.find(x=> x.id == id);
    else
      produtos = this.produtosDeDevolucao[0];

    produtos.lotear = lote != 1 && !produtos.lotear;
  }

  setValorPagoPelocliente(data){
    this.valorPagoPeloCliente = data.target.value != '' ? Number.parseFloat(data.target.value.toString().replace(',', `.`)) : 0;
    this.calcularPrecoTotalDevolucao();
  }

  ResgatarProdutoDevolucao(id){
    let produto = this.produtosDeDevolucao.find(x=> x.id == id);
    if(produto.limiteDeDelucao > produto.quantidade){
      if(produto == undefined) produto = new Compra();

      if(produto.lotear){
        produto.quantidade += (1 * produto.lote);
        produto.precoTotal += (produto.preco * produto.lote)
      } else {
        produto.quantidade += 1;
        produto.precoTotal += produto.preco
      }

    }
    else{
      produto.quantidade = produto.limiteDeDelucao;
      produto.precoTotal = (produto.preco * produto.limiteDeDelucao);
    }
    this.calcularPrecoTotalDevolucao();
  }

  NaoResgatarProdutoDevolucao(id){
    let produto = this.produtosDeDevolucao.find(x=> x.id == id);
      if(produto.quantidade > 0){
        if(produto.lotear) {

          if((produto.quantidade - (1 * produto.lote)) > 0){
            produto.quantidade -= (1 * produto.lote);
            produto.precoTotal -= (produto.preco * produto.lote);
          }else{
            produto.quantidade = 0;
            produto.precoTotal = 0;
          }
  
        } else {
          produto.quantidade -= 1;
          produto.precoTotal -= produto.preco;
        }
        this.calcularPrecoTotalDevolucao();
      }
  }

  calcularPrecoTotalDevolucao(){
    let precoFinal = this.produtosDeDevolucao.reduce((precoTotal, item) => precoTotal + (item.preco * item.quantidade), 0);
    let restante = (this.intinerario.valor - this.intinerario.valor_adiantado);
    this.valorDaDevolucao = parseFloat((((restante - precoFinal) -this.valorPagoPeloCliente)*(-1)).toFixed(2));
  }

  async carregarIntinerario(){
    this.id = this.route.snapshot.params['id'];
    if(this.id != undefined){
      let intinerario = await this.intinerarioService.encontrarPeloId(this.id);
      let produtos = await this.intinerarioService.encontrarProdutosPeloIdIntinerario(this.id);
      this.intinerario = intinerario.value[0]
      this.valorDaDevolucao = ((this.intinerario.valor - (this.intinerario.valor*2)) + this.intinerario.valor_adiantado) - this.intinerario.valor_pago;
      this.montarDataListDeDevolucaoProduto(produtos.value);
    }
  }

  fecharModal(e){
    if(e.event) { 
      if(((this.valorDaDevolucao * (-1)) - this.intinerario.valor_pago)  + this.intinerario.incorporacao > 0){
        this.cupom.id_cliente = this.intinerario.id_cliente;
        this.cupom.data_cobranca = e.data;
        this.cupom.valor = ((this.valorDaDevolucao * (-1)) - this.intinerario.valor_pago)  + this.intinerario.incorporacao;
        this.cupom.incorporacao = null;
        this.cupom.created_by = this.currentUser.id;
        this.cupom.flag_pago = false;
        this.cupom.valor_pago = this.valorPagoPeloCliente;
        this.salvarCupomBaixaItinerario();
      }
      else
      {
        this.cupom.id_cliente = this.intinerario.id_cliente;
        this.cupom.data_cobranca = e.data;
        this.cupom.valor = ((this.valorDaDevolucao * (-1)) - this.intinerario.valor_pago)  + this.intinerario.incorporacao;
        this.cupom.incorporacao = null;
        this.cupom.created_by = this.currentUser.id;
        this.cupom.flag_pago = false;
        this.cupom.valor_pago = this.valorPagoPeloCliente;
        this.salvar();
      }
    }
    this.exibir = e.event;
    this.showOverlay = e.event;
  }

  async salvarCupomBaixaItinerario(){
    await this.intinerarioService.repasseSeguidoDeBaixa({ intinerario: this.intinerario, cupom: this.cupom, id: this.id, id_cupom: this.intinerario.id_cupom, produtos: this.produtosDeDevolucao, idUsuario: this.currentUser.id, role: this.currentUser.role}).then(x=>{
      this.router.navigate(['/intinerario'],  { queryParams: { message: 1 }} );
      this.showOverlay = false;
    })
    .catch(x=>{
      this.router.navigate(['/intinerario'],  { queryParams: { message: 0 }} );
      this.showOverlay = false;
    });
  }

  construirFiltroDeConsulta(){
    this.filtro.quantidadePorPargina = 10;
    this.filtro.pagina = 1
  }
  
  async montarDataListDeDevolucaoProduto(produtos){
    if(produtos.length){
      this.construirInputsProdutosDeDevolucao(produtos);
    }
  }

  construirInputsProdutosDeDevolucao(produto:any[]){
    produto.forEach(x=>{
      let compra = new Compra();
      compra.id = x.id;
      compra.preco = x.preco;
      compra.nome_pai = x.nome_pai;
      compra.nome_filho = x.nome_filho;
      compra.precoTotal = x.preco * x.quantidade;
      compra.limiteDeDelucao = x.quantidade;
      compra.lote = x.lote;
      compra.flag_devolucao = x.flag_devolucao;
      this.produtosDeDevolucao.push(compra);
    });
  }

  async montarCupom(){
    this.cupom.id_cliente = this.intinerario.id_cliente;
    this.cupom.flag_pago = false;
    this.cupom.valor = (this.valorDaDevolucao * (-1));
    this.cupom.flag_pago = false;
    this.cupom.valor_pago = this.valorPagoPeloCliente;
    
    return {
      cupom: this.cupom,
      role: this.currentUser.role,
      produtos: await this.montarProdutos(),
      idUsuario: this.currentUser.id
    }
  }

  montarProdutos(){
    let produtos: Compra[] = [];
    this.produtosDeDevolucao.forEach(x=>{
      if(x.quantidade != x.limiteDeDelucao){
        let produto = new Compra();
        produto.id = x.id;
        produto.quantidade = x.limiteDeDelucao - x.quantidade;
        produto.preco = x.preco;
        produto.nome_pai = x.nome_pai;
        produto.nome_filho = x.nome_filho;
        produto.precoTotal = x.precoTotal
        produtos.push(produto)
      }
    });
    return produtos;
  }
  
  async submit(){

    this.cupom.id_cliente = this.intinerario.id_cliente;
    this.cupom.valor = ((this.valorDaDevolucao * (-1)) - this.intinerario.valor_pago)  + this.intinerario.incorporacao;
    this.cupom.incorporacao = null;
    this.cupom.created_by = this.currentUser.id;
    this.cupom.flag_pago = false;
    this.cupom.valor_pago = this.valorPagoPeloCliente;
    let valor = ((this.valorDaDevolucao * (-1)) - this.intinerario.valor_pago)  + this.intinerario.incorporacao;

    if(this.intinerario.incorporacao == undefined){
      if(valor  != 0)
        this.exibir = true;
      else
        this.salvar();
    }
    else
    {
      if(valor != 0)
        this.exibir = true;
      else
        this.salvar();
    }
  }

  async salvar(){
    this.showOverlay = true;

    await this.intinerarioService.editar({  cupom: this.cupom, intinerario: this.intinerario, produtos: this.produtosDeDevolucao, idUsuario: this.currentUser.id, role: this.currentUser.role, pagamento: (((this.valorDaDevolucao * (-1)) - this.intinerario.valor_pago)  + this.intinerario.incorporacao) * (-1) })
    .then(x=>{
      this.router.navigate(['/intinerario'],  { queryParams: { message: 1 }} );
      this.showOverlay = false;
    })
    .catch(x=>{
      this.router.navigate(['/intinerario'],  { queryParams: { message: 0 }} );
      this.showOverlay = false;
    });
  }
}
