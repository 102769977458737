<nav class="navbar navbar-expand-lg navbar-dark bg-danger">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" routerLink="/">Home</a>
      </li>
      <li class="nav-item active" *ngIf="currentUser.id == 1">
        <a class="nav-link" routerLink="/usuario">Usuarios</a>
      </li>
      <li class="nav-item active" *ngIf="currentUser.id == 1">
        <a class="nav-link" routerLink="/produto">Produtos</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/cliente">Clientes</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/cupom">Cupons</a>
      </li>
      <li class="nav-item active" *ngIf="currentUser.id == 1">
        <a class="nav-link" routerLink="/rota">Rotas</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/intinerario">Itinerario</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerlink="javascript:void(0)" (click)="logout();">Sair</a>
      </li>
    </ul>
  </div>
</nav>  