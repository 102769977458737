export class Cliente {
    id: number;
    nome: string;
    cpf: string;
    telefone: string;
    whatsapp:string;
    rua:string;
    numero:string;
    cep:string;
    complemento:string;
    sub_bairro:string;
    id_bairro:string;
    id_cidade:string;
    created_by:number;
  }