import { Compra } from "./compra.model";
import { Incorporacao } from "./incorporacao.model";

export class Cupom {
    id: number;
    id_cliente:number = 0;
    data_cobranca:string = '';
    valor:number = 0;
    valor_adiantado:string = '0';
    valor_pago:number = 0;
    desconto:number = 0;
    flag_pago: boolean;
    flag_repasse: boolean;
    flag_ativo: boolean;
    created_date:string;
    Produtos: Compra[];
    incorporacao: Incorporacao;
    created_by:number;
}